<template>
  <div>
    <div class="box-shadow bg-white">
      <div class="d-flex-center notice-bar p-20 mb-30">
        <div v-for="(item, index) in noticeList" :key="index">
          <el-link type="primary" :title="item.title" class="link" @click="$nav.push('/notice/details/' + item.id)">{{
          item.title }}</el-link>
          <span class="mx-20">/</span>
        </div>
        <div>
          <el-link type="primary" class="link" @click="$nav.push('/notice')">{{
          $t("n_more")
        }}</el-link>
        </div>
      </div>
    </div>
    <el-card class="content-box">
      <div class="top-info py-20">
        <div class="left-textcontent">
          <h1 class="mr-auto pair-coin my-0">
            {{ $route.params.pair.toUpperCase() }} /
            {{ $route.params.coin.toUpperCase() }}
          </h1>
        </div>

        <div class="right-textcontent">
          <div class="price">
            <div class="font-24 price-value" :class="{
          'color-s': info.change > 0,
          'color-d': info.change < 0,
        }">
              {{ info.lasest_price }}
            </div>
            <div class="mt-5 color-6 font-14">= {{ info.usd }} (USD)</div>
          </div>
          <div class="change text-center">
            <div v-if="info.change > 0" class="font-20 color-s">
              +{{ info.change }}%
            </div>
            <div v-else-if="info.change == 0" class="font-20">
              {{ info.change }}%
            </div>
            <div v-else class="font-20 color-d">{{ info.change }}%</div>
            <div class="mt-5 color-6 font-14">24H {{ $t("home.change") }}</div>
          </div>
          <div class="high text-center">
            <div class="font-20">{{ info.high && info.high }}</div>
            <div class="mt-5 color-6 font-14">
              24H {{ $t("home.high") }} ({{ $route.params.coin }})
            </div>
          </div>
          <div class="low text-center">
            <div class="font-20">{{ info.low && info.low }}</div>
            <div class="mt-5 color-6 font-14">
              24H {{ $t("home.low") }} ({{ $route.params.coin }})
            </div>
          </div>
          <!-- <div class="finished text-center">
            <div class="font-20">
              {{ info.volume_24h && info.volume_24h }}
            </div>
            <div class="mt-5 color-6 font-14">
              24H {{ $t("home.finished") }} ({{ $route.params.pair }})
            </div>
          </div> -->
        </div>
      </div>
      <div class="kline-box mt-20">
        <div class="kline-tools font-bold">
          <!-- k线面积图 -->
          <el-button :type="Period == 4 && drawType ? 'success' : 'primary'" size="mini" plain class="bg-tw-50"
            @click="ChangePeriod(4, true)">Time</el-button>

          <el-button :type="Period == 4 && !drawType ? 'success' : 'primary'" size="mini" plain class="bg-tw-50"
            @click="ChangePeriod(4, false)">1Min</el-button>
          <el-button :type="Period == 5 ? 'success' : 'primary'" size="mini" plain class="bg-tw-50"
            @click="ChangePeriod(5)">5Min</el-button>
          <el-button :type="Period == 6 ? 'success' : 'primary'" size="mini" plain class="bg-tw-50"
            @click="ChangePeriod(6)">15Min</el-button>
          <el-button :type="Period == 7 ? 'success' : 'primary'" size="mini" plain class="bg-tw-50"
            @click="ChangePeriod(7)">30Min</el-button>
          <el-button :type="Period == 0 ? 'success' : 'primary'" size="mini" plain class="bg-tw-50"
            @click="ChangePeriod(0)">1Day</el-button>
        </div>
        <div class="kline-view" ref="kline" style="height: 500px; width: 1160px; position：relative" />
      </div>
    </el-card>
    <div class="content-box mb-50 mt-20">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="bg-white box-shadow border-r">
            <div class="tabs d-flex-center border-bottom font-14">
              <div class="tab px-25 pt-20 flex-1 mr-auto" :class="{ active: marketCoin == 'USDT' }"
                @click="onTab('USDT')">
                USDT
              </div>
              <!-- <div class="tab px-25 pt-20 flex-1" :class="{ active: marketCoin == 'BTC' }" @click="onTab('BTC')">
                BTC
              </div>
              <div class="tab px-25 pt-20 flex-1" :class="{ active: marketCoin == 'ETH' }" @click="onTab('ETH')">
                ETH
              </div> -->
            </div>
            <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-gray px-20 py-15 font-12">
              <el-col :span="8">Pair</el-col>
              <el-col :span="10">Price</el-col>
              <el-col class="text-right" :span="6">Change</el-col>
            </el-row>
            <div v-for="(item, index) in market" :key="index" class="is-link"
              @click="$nav.replace('/contact/' + item.pair + '/' + item.coin)">
              <el-row :gutter="10" type="flex" justify="center" align="middle" class="market-body px-20 py-15">
                <el-col :span="3">
                  <i v-if="item.logo" class="icon icon-stars" :style="{ backgroundImage: 'url(' + item.logo + ')' }" />
                  <i v-else class="icon icon-stars" :class="'icon-coin-' + item.pair.toLowerCase()" />
                </el-col>
                <el-col :span="5" class="font-14">{{
          item.pair.toUpperCase()
        }}</el-col>
                <el-col :span="10" class="font-14">{{
          item.lasest_price
        }}</el-col>
                <el-col :span="6" class="text-right">
                  <el-tag v-if="item.change > 0" class="change" type="success" effect="dark" size="small">+{{
          item.change }}%</el-tag>
                  <el-tag v-else-if="item.change == 0" class="change" type="warning" effect="dark"
                    size="small">0.00%</el-tag>
                  <el-tag v-else class="change" type="danger" effect="dark" size="small">{{ item.change }}%</el-tag>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <!-- 下单模块 -->
          <div class="bg-white box-shadow border-r mb-20 tabs-outline">
            <!-- <h3 class="theme-title"><span>现货</span></h3> -->

            <!-- 资金互转 -->
            <el-dialog :title="$t('contact_about.fundswap')" :visible.sync="dialogVisible" width="30%"
              @closed="closeDialog">
              <!-- 互转内容 -->
              <div class="trading">
                <div class="trading-box">
                  <div class="trading-box1">
                    <div class="form-point"></div>
                    <div class="point-list">
                      <div class="point"></div>
                      <div class="point"></div>
                      <div class="point"></div>
                    </div>
                    <div class="to-point"></div>
                  </div>
                  <div class="trading-box2">
                    <div class="trading-box2-into from">
                      <div class="trading-box2-title">
                        {{ $t("contact_about.form") }}
                      </div>
                      <div class="uni-list-cell-db">
                        <span>{{ recharge_text1 }}</span>
                      </div>
                    </div>
                    <div class="trading-box2-into to">
                      <div class="trading-box2-title">
                        {{ $t("contact_about.to") }}
                      </div>
                      <div class="uni-list-cell-db">
                        <span>{{ recharge_text2 }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="trading-box3">
                    <div class="switch" @click="rechargeSwitch">
                      <img class="huzhuan-icon" :src="swi" />
                    </div>
                  </div>
                </div>
                <div class="text-content">
                  <div class="text-content-item">
                    <div class="text-c-h">
                      {{ $t("contact_about.pair") }}
                    </div>
                    <div class="text-c-p">
                      <input class="coin inp" value="USDT" disabled />
                    </div>
                  </div>
                  <div class="text-content-item">
                    <div class="text-c-h">
                      {{ $t("contact_about.num") }}
                    </div>
                    <div class="text-c-p text-c-p1">
                      <input class="amount inp" v-model="amount_recharge" :placeholder="$t('contact_about.num_plc')" />
                      <div class="amount-after">
                        <div class="amount-unit">USDT</div>
                        <div class="cli"></div>
                        <div class="all" @click="amount_recharge = amount_balance">
                          {{ $t("contact_about.all") }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="balance">
                    <div class="balance_h">
                      {{ $t("contact_about.available") }}：
                    </div>
                    <div class="balance_p">
                      {{ amount_balance }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- 互转内容 -end -->

              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">{{
          $t("contact_about.cancel")
        }}</el-button>
                <el-button type="primary" @click="submitRecharge" size="small" :loading="btn_load">{{
          $t("contact_about.confirm") }}</el-button>
              </span>
            </el-dialog>
            <!-- 资金互转 -end -->

            <el-tabs v-model="activeName" @tab-click="handleClick" class="newstyle">
              <!-- 合约 市價 -->
              <!-- trading_index.el_title -->
              <el-tab-pane :label="$t('trading_index.tab_1')" name="first">
                <div class="top-text">
                  <div class="top-text-item">
                    <div class="top-text-p" :class="Number(userContract.net_worth) > 0
            ? 'color-s'
            : Number(userContract.net_worth) < 0
              ? 'color-d'
              : ''
          ">
                      {{ userContract.net_worth }}
                    </div>
                    <div class="top-text-h">
                      {{ $t("contact_about.top_text_h1") }}
                    </div>
                  </div>
                  <div class="top-text-item">
                    <div class="top-text-p">
                      {{ userContract.cash_deposit_total }}
                    </div>
                    <div class="top-text-h">
                      {{ $t("contact_about.top_text_h2") }}
                    </div>
                  </div>
                  <div class="top-text-item">
                    <div class="top-text-p" :class="Number(userContract.income) > 0
            ? 'color-s'
            : Number(userContract.income) < 0
              ? 'color-d'
              : ''
          ">
                      {{ userContract.income }}
                    </div>
                    <div class="top-text-h">
                      {{ $t("contact_about.top_text_h3") }}
                    </div>
                  </div>
                </div>

                <el-row :gutter="20">
                  <!-- 余额 -->
                  <el-col :span="12">
                    <div class="mb-20 d-flex-center font-14 font-bold color-666 newstyle-col" style="line-height: 28px">
                      <span class="mr-auto">
                        {{ $t("contact_about.available") }} :
                        <strong>{{ userContract.balance }}</strong>
                      </span>
                      <span>
                        <el-button type="primary" size="mini" icon="el-icon-sort" style="padding: 3px 6px"
                          :loading="dataVerifyFlag" @click="transferFn">{{ $t("contact_about.fundswap") }}</el-button>
                      </span>
                    </div>
                  </el-col>
                  <!-- 杠杆 -->
                  <el-col :span="12">
                    <div class="mb-20 d-flex-center font-14 font-bold color-666 newstyle-col">
                      <span class="mr-auto" style="white-space: nowrap">{{ $t("contact_about.lever") }} :
                      </span>
                      <div class="in-select">
                        <el-select v-model="lever1" :placeholder="$t('contact_about.please_choose')" size="mini"
                          @change="selectFn">
                          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="12">
                    <div>
                      <el-form ref="buyFormShijia" v-loading="buyLoading_shijia" class="input-form mx-auto"
                        :model="buyFormData_shijia" :rules="rules">
                        <!-- <div
                          class="mb-20 d-flex-center font-14 font-bold color-666"
                          style="line-height: 28px"
                        >
                          <span class="mr-auto"
                            >{{ $t("contact_about.available") }} :
                          </span>
                          <span
                            >{{ userContract.balance }}
                            <el-button
                              type="primary"
                              size="mini"
                              icon="el-icon-sort"
                              style="padding: 3px 6px"
                              @click="transferFn"
                              >{{ $t("contact_about.fundswap") }}</el-button
                            >
                          </span>
                        </div> -->
                        <el-form-item>
                          <div class="youjia">
                            <span>{{ $t("youjia") }}</span>
                          </div>
                        </el-form-item>
                        <!-- 2023.2.16市价的数量改为保证金，提交字段amount不变 -->
                        <el-form-item prop="amount">
                          <el-input v-model="buyFormData_shijia.amount"
                            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" prefix-icon="el-icon-money"
                            :placeholder="$t('contact_about.margin')" @input="marginFn1">
                            <!-- 数量 -->
                          </el-input>
                        </el-form-item>

                        <!-- 2023.2.16新增拖条 -->
                        <el-form-item>
                          <div class="slider_box">
                            <div class="slider_box_into">
                              <el-slider v-model="slider_value1_shijia" :show-tooltip="false" class="slider_style_class"
                                input-size="mini" @input="formInputSlider1_shijia">
                              </el-slider>
                            </div>
                            <div class="slider_box_tooltip">
                              {{
          slider_value1_shijia
            ? slider_value1_shijia + "%"
            : 0
        }}
                            </div>
                          </div>
                        </el-form-item>

                        <!-- 2023.2.16市价新增剩余保证金 -->
                        <el-form-item>
                          <el-input readonly v-model="margin_last1" prefix-icon="el-icon-money"
                            :placeholder="$t('contact_about.margin1')">
                          </el-input>
                        </el-form-item>

                        <!-- 買入 -->
                        <el-form-item>
                          <el-button type="success" icon="el-icon-s-promotion" class="w-100" native-type="button"
                            @click="submitBuyForm_shijia()">{{ $t("c_buy") }}</el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div>
                      <el-form ref="sellFormShijia" v-loading="sellLoading_shijia" class="input-form mx-auto"
                        :model="sellFormData_shijia" :rules="rules">
                        <!-- <div
                          class="mb-20 d-flex-center font-14 font-bold color-666"
                        >
                          <span class="mr-auto" style="white-space: nowrap"
                            >{{ $t("contact_about.lever") }} :
                          </span>
                          <div class="in-select">
                            <el-select
                              v-model="lever1"
                              :placeholder="$t('contact_about.please_choose')"
                              size="mini"
                              @change="selectFn"
                            >
                              <el-option
                                v-for="item in options1"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </div> -->
                        <el-form-item>
                          <div class="youjia">
                            <span>{{ $t("youjia") }}</span>
                          </div>
                        </el-form-item>
                        <!-- 2023.2.16市价的数量改为保证金，提交字段amount不变 -->
                        <el-form-item prop="amount">
                          <el-input v-model="sellFormData_shijia.amount"
                            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" prefix-icon="el-icon-money"
                            @input="marginFn1" :placeholder="$t('contact_about.margin')">
                            <!-- 数量 -->
                          </el-input>
                        </el-form-item>

                        <!-- 2023.2.16新增拖条 -->
                        <el-form-item>
                          <div class="slider_box">
                            <div class="slider_box_into">
                              <el-slider v-model="slider_value2_shijia" :show-tooltip="false" class="slider_style_class"
                                input-size="mini" @input="formInputSlider2_shijia">
                              </el-slider>
                            </div>
                            <div class="slider_box_tooltip">
                              {{
          slider_value2_shijia
            ? slider_value2_shijia + "%"
            : 0
        }}
                            </div>
                          </div>
                        </el-form-item>

                        <!-- 2023.2.16市价新增剩余保证金 -->
                        <el-form-item>
                          <el-input readonly v-model="margin_last2" prefix-icon="el-icon-money"
                            :placeholder="$t('contact_about.margin1')">
                          </el-input>
                        </el-form-item>

                        <!-- 賣出 -->
                        <el-form-item>
                          <el-button type="danger" icon="el-icon-s-promotion" class="w-100" native-type="button"
                            @click="submitSellForm_shijia()">{{ $t("c_sell") }}</el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <!-- 合约 市價 -end -->

              <!-- 合约 限價 -->
              <el-tab-pane :label="$t('trading_index.tab_2')" name="second">
                <div class="top-text">
                  <div class="top-text-item">
                    <div class="top-text-p" :class="Number(userContract.net_worth) > 0
            ? 'color-s'
            : Number(userContract.net_worth) < 0
              ? 'color-d'
              : ''
          ">
                      {{ userContract.net_worth }}
                    </div>
                    <div class="top-text-h">
                      {{ $t("contact_about.top_text_h1") }}
                    </div>
                  </div>
                  <div class="top-text-item">
                    <div class="top-text-p">
                      {{ userContract.cash_deposit_total }}
                    </div>
                    <div class="top-text-h">
                      {{ $t("contact_about.top_text_h2") }}
                    </div>
                  </div>
                  <div class="top-text-item">
                    <div class="top-text-p" :class="Number(userContract.income) > 0
            ? 'color-s'
            : Number(userContract.income) < 0
              ? 'color-d'
              : ''
          ">
                      {{ userContract.income }}
                    </div>
                    <div class="top-text-h">
                      {{ $t("contact_about.top_text_h3") }}
                    </div>
                  </div>
                </div>

                <el-row :gutter="20">
                  <!-- 余额 -->
                  <el-col :span="12">
                    <div class="mb-20 d-flex-center font-14 font-bold color-666 newstyle-col" style="line-height: 28px">
                      <span class="mr-auto">{{ $t("contact_about.available") }} :
                        <strong>{{ userContract.balance }}</strong>
                      </span>
                      <span>{{ userContract.balance }}
                        <el-button type="primary" size="mini" icon="el-icon-sort" style="padding: 3px 6px"
                          :loading="dataVerifyFlag" @click="transferFn">{{ $t("contact_about.fundswap") }}</el-button>
                      </span>
                    </div>
                  </el-col>
                  <!-- 杠杆 -->
                  <el-col :span="12">
                    <div class="mb-20 d-flex-center font-14 font-bold color-666 newstyle-col">
                      <span class="mr-auto" style="white-space: nowrap">{{ $t("contact_about.lever") }} :
                      </span>
                      <div class="in-select">
                        <el-select v-model="lever1" :placeholder="$t('contact_about.please_choose')" size="mini"
                          @change="selectFn">
                          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="12">
                    <div>
                      <el-form ref="buyForm1" v-loading="buyLoading" class="input-form mx-auto" :model="buyFormData1"
                        :rules="rules">
                        <!-- <div
                          class="mb-20 d-flex-center font-14 font-bold color-666"
                          style="line-height: 28px"
                        >
                          <span class="mr-auto"
                            >{{ $t("contact_about.available") }} :
                          </span>
                          <span
                            >{{ userContract.balance }}
                            <el-button
                              type="primary"
                              size="mini"
                              icon="el-icon-sort"
                              style="padding: 3px 6px"
                              @click="transferFn"
                              >{{ $t("contact_about.fundswap") }}</el-button
                            >
                          </span>
                        </div> -->
                        <el-form-item prop="price">
                          <!-- <el-input
                            v-model="buyFormData1.price"
                            type="number"
                            prefix-icon="el-icon-money"
                            :placeholder="$t('price')"
                            @focus="
                              new_price_input
                                ? (buyFormData1.price = new_price_input)
                                : ''
                            "
                            @input="formInputPrice"
                          >
                            <span class="color-333" slot="append">{{
                              $route.params.coin
                            }}</span>
                          </el-input> -->
                          <el-input v-model="buyFormData1.price" type="number" prefix-icon="el-icon-money"
                            :placeholder="$t('price')" @focus="formInputFocus" @input="formInputPrice">
                            <span class="color-333" slot="append">{{
          $route.params.coin
        }}</span>
                          </el-input>
                        </el-form-item>

                        <el-form-item prop="amount">
                          <el-input v-model="buyFormData1.amount" type="number" prefix-icon="el-icon-coin"
                            :placeholder="$t('contact_about.num')" @input="formInputAmount">
                            <span class="color-333" slot="append">{{
          $route.params.pair
        }}</span>
                          </el-input>
                        </el-form-item>

                        <!-- 拖条 -->
                        <el-form-item>
                          <div class="slider_box">
                            <div class="slider_box_into">
                              <el-slider v-model="slider_value" :show-tooltip="false" class="slider_style_class"
                                input-size="mini" @input="formInputSlider">
                              </el-slider>
                            </div>
                            <div class="slider_box_tooltip">
                              {{ slider_value ? slider_value + "%" : 0 }}
                            </div>
                          </div>
                        </el-form-item>

                        <el-form-item>
                          <el-input readonly prefix-icon="el-icon-money" :placeholder="$t('contact_about.margin')"
                            @input="formInputTotalCoin" v-model="all_buy">
                            <span class="color-333" slot="append">{{
          $route.params.coin
        }}</span>
                          </el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="success" icon="el-icon-s-promotion" class="w-100" native-type="button"
                            @click="submitBuyForm1()">{{ $t("c_buy") }}</el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div>
                      <el-form ref="sellForm1" v-loading="sellLoading" class="input-form mx-auto" :model="sellFormData1"
                        :rules="rules">
                        <!-- <div
                          class="mb-20 d-flex-center font-14 font-bold color-666"
                        >
                          <span class="mr-auto" style="white-space: nowrap"
                            >{{ $t("contact_about.lever") }} :
                          </span>
                          <div class="in-select">
                            <el-select
                              v-model="lever1"
                              :placeholder="$t('contact_about.please_choose')"
                              size="mini"
                              @change="selectFn"
                            >
                              <el-option
                                v-for="item in options1"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </div> -->
                        <el-form-item prop="price">
                          <el-input v-model="sellFormData1.price" type="number" prefix-icon="el-icon-money"
                            :placeholder="$t('price')" @focus="formInputFocus" @input="formInputPrice1">
                            <span class="color-333" slot="append">{{
          $route.params.coin
        }}</span>
                          </el-input>
                          <!-- <el-input
                            v-model="sellFormData1.price"
                            type="number"
                            prefix-icon="el-icon-money"
                            :placeholder="$t('price')"
                            @focus="
                              new_price_input
                                ? (sellFormData1.price = new_price_input)
                                : ''
                            "
                            @input="formInputPrice1"
                          >
                            <span class="color-333" slot="append">{{
                              $route.params.coin
                            }}</span>
                          </el-input> -->
                        </el-form-item>
                        <el-form-item prop="amount">
                          <el-input v-model="sellFormData1.amount" type="number" prefix-icon="el-icon-coin"
                            :placeholder="$t('contact_about.num')" @input="formInputAmount1">
                            <span class="color-333" slot="append">{{
          $route.params.pair
        }}</span>
                          </el-input>
                        </el-form-item>

                        <!-- 拖条 -->
                        <el-form-item>
                          <div class="slider_box">
                            <div class="slider_box_into">
                              <el-slider v-model="slider_value1" :show-tooltip="false" class="slider_style_class"
                                input-size="mini" @input="formInputSlider1">
                              </el-slider>
                            </div>
                            <div class="slider_box_tooltip">
                              {{ slider_value1 ? slider_value1 + "%" : 0 }}
                            </div>
                          </div>
                        </el-form-item>

                        <el-form-item>
                          <el-input readonly prefix-icon="el-icon-money" :placeholder="$t('contact_about.margin')"
                            @input="formInputTotalCoin1" v-model="all_sell">
                            <span class="color-333" slot="append">{{
          $route.params.coin
        }}</span>
                          </el-input>
                        </el-form-item>
                        <el-form-item>
                          <el-button type="danger" icon="el-icon-s-promotion" class="w-100" native-type="button"
                            @click="submitSellForm1()">{{ $t("c_sell") }}</el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <!-- 合约 限價 -end -->
            </el-tabs>
          </div>

          <!-- 订单列表 -->
          <div class="bg-white box-shadow border-r" v-loading="tradingLoading">
            <el-tabs v-model="activeName1" @tab-click="handleClick1" class="newstyle">
              <!-- <el-tab-pane label="现货订单" name="first">
                <div class="etab-top">
                  <div class="title-label">
                    <div class="title-label-item active">当前委托</div>
                  </div>
                  <div class="title-switch">
                    <el-switch
                      v-model="switch1"
                      @change="changeFn1"
                      active-text="只看当前品种"
                    >
                    </el-switch>
                  </div>
                </div>

                <el-row
                  :gutter="20"
                  type="flex"
                  justify="center"
                  align="middle"
                  class="color-gray py-15 border-bottom font-14"
                >
                  <el-col :span="4">Type</el-col>
                  <el-col :span="6">Price</el-col>
                  <el-col :span="5">Amount</el-col>
                  <el-col :span="4">Time</el-col>
                  <el-col class="text-right" :span="4">Deal</el-col>
                </el-row>
                <el-row
                  v-for="(item, index) in tradingList"
                  :key="index"
                  :gutter="20"
                  type="flex"
                  justify="center"
                  align="middle"
                  class="py-15 border-bottom font-14"
                >
                  <el-col :span="4">
                    <el-tag
                      v-if="item.type"
                      type="info"
                      effect="plain"
                      hit
                      class="bg-t"
                      >Sell</el-tag
                    >
                    <el-tag
                      v-else
                      type="primary"
                      effect="plain"
                      hit
                      class="bg-t"
                      >Buy</el-tag
                    >
                  </el-col>
                  <el-col :span="6">{{ item.entrust_price }}</el-col>
                  <el-col :span="5">{{ item.amount }}</el-col>
                  <el-col :span="4">{{ item.create_time }}</el-col>
                  <el-col class="text-right" :span="4">
                    <el-button
                      type="danger"
                      size="small"
                      plain
                      class="bg-t"
                      @click="onCancelTradingOrder(item.id)"
                      >Cancel</el-button
                    >
                  </el-col>
                </el-row>

                <div
                  v-if="tradingList.length < 1"
                  class="py-50 font-14 color-i text-center"
                >
                  No More
                </div>

                <div class="pagination-box" v-else>
                  <el-pagination
                    layout="prev, pager, next"
                    :total="total1"
                    :page-size="pageSize1"
                    :current-page="pageNum1"
                    @current-change="pageFn1"
                  >
                  </el-pagination>
                </div>
              </el-tab-pane> -->

              <el-tab-pane :label="$t('contact_about.contract_order')" name="second">
                <div class="etab-top">
                  <div class="title-label">
                    <div class="title-label-item" @click="labelStateFn(0)" :class="labelState == 0 ? 'active' : ''">
                      {{ $t("contact_about.position_order") }}
                    </div>
                    <span class="title-label-cli"></span>
                    <div class="title-label-item" @click="labelStateFn(1)" :class="labelState == 1 ? 'active' : ''">
                      {{ $t("contact_about.current_commission") }}
                    </div>
                  </div>
                  <div class="title-switch">
                    <el-switch v-model="switch2" @change="changeFn2" :active-text="$t('contact_about.switchBtn')">
                    </el-switch>
                  </div>
                </div>

                <!-- 持仓 -->
                <div class="list-outline" v-if="labelState == 0">
                  <el-row :gutter="20" type="flex" justify="center" align="middle"
                    class="color-gray py-15 border-bottom font-14">
                    <el-col :span="6">{{
          $t("contact_about.table1.pair")
        }}</el-col>
                    <el-col :span="4">{{
          $t("contact_about.table1.type")
        }}</el-col>
                    <el-col :span="6">{{
          $t("contact_about.table1.income")
        }}</el-col>
                    <el-col :span="6">{{
          $t("contact_about.table1.rate_return")
        }}</el-col>
                    <el-col :span="4">{{
          $t("contact_about.table1.service_charge")
        }}</el-col>
                    <el-col class="text-right" :span="4">{{
          $t("contact_about.table1.deal")
        }}</el-col>
                  </el-row>

                  <div class="contactlist-item" v-for="(item, index) in contactList" :key="index">
                    <div class="contactlist-item-into" @click="item.flag = !item.flag"
                      :class="item.flag ? 'contactlist-item-active' : ''">
                      <el-row :gutter="20" type="flex" justify="center" align="middle"
                        class="py-15 border-bottom font-14">
                        <el-col :span="6">
                          <div class="coin-zoom">
                            <span class="coin-zoom-icon">
                              <i class="el-icon-caret-bottom"></i>
                            </span>
                            <div class="coin-zoom-into">
                              <strong>{{ item.pair.toUpperCase() }} /
                                {{ item.coin.toUpperCase() }}</strong>
                              <!-- <span>Buy</span> -->
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="4">
                          <span :class="item.type ? 'color-d' : 'color-s'">
                            {{ item.type ? "Sell" : "Buy" }}
                          </span>
                        </el-col>
                        <el-col :span="6">
                          <span :class="item.income > 0
            ? 'color-s'
            : item.income < 0
              ? 'color-d'
              : ''
          ">
                            {{ item.income }}
                          </span>
                        </el-col>
                        <el-col :span="6">
                          <span :class="floatingpoint.bed(
          Number(item.income),
          Number(item.cash_deposit)
        ) < 0
            ? 'color-d'
            : floatingpoint.bed(
              Number(item.income),
              Number(item.cash_deposit)
            ) > 0
              ? 'color-s'
              : ''
          ">
                            {{
          floatingpoint
            .mul(
              floatingpoint.bed(
                Number(item.income),
                Number(item.cash_deposit)
              ),
              100
            )
            .toFixed(2) + "%"
        }}
                          </span>
                        </el-col>
                        <el-col :span="4">
                          <span>
                            {{ item.service_charge }}
                          </span>
                        </el-col>
                        <el-col class="text-right" :span="4">
                          <el-button type="danger" size="small" plain class="bg-t" @click.stop="cleanFn(item.id)" style="padding: 9px 8px;box-sizing: border-box;">{{
          $t("contact_about.liquidation") }}</el-button>
                        </el-col>
                      </el-row>
                      <div class="contactlist-item-detail">
                        <div class="contactlist-item-detail-into">
                          <div class="detail-row">
                            <div class="detail-col">
                              <span class="span-h">{{
          $t("contact_about.table1.span_h1")
        }}</span>
                              <span class="span-p">{{
            Number(item.entrust_price)
          }}</span>
                            </div>
                            <div class="detail-col">
                              <span class="span-h">{{
            $t("contact_about.table1.span_h2")
          }}</span>
                              <span class="span-p">{{
            Number(item.open_price)
          }}</span>
                            </div>
                            <div class="detail-col">
                              <span class="span-h">{{
            $t("contact_about.table1.span_h3")
          }}</span>
                              <span class="span-p">{{
            Number(item.cash_deposit)
          }}</span>
                            </div>
                            <div class="detail-col">
                              <span class="span-h">{{
            $t("contact_about.table1.span_h4")
          }}</span>
                              <span class="span-p">{{
            Number(item.new_price)
          }}</span>
                            </div>
                          </div>
                          <div class="detail-row">
                            <div class="detail-col">
                              <span class="span-h">{{
            $t("contact_about.table1.span_h5")
          }}</span>
                              <span class="span-p">{{ item.create_time }}</span>
                            </div>
                            <div class="detail-col">
                              <span class="span-h">{{
          $t("contact_about.table1.span_h6")
        }}</span>
                              <span class="span-p">{{ item.open_time }}</span>
                            </div>
                            <div class="detail-col">
                              <span class="span-h">{{
          $t("contact_about.table1.span_h7")
        }}</span>
                              <span class="span-p">{{ item.level + "x" }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 委托 -->
                <div class="list-outline" v-if="labelState == 1">
                  <el-row :gutter="20" type="flex" justify="center" align="middle"
                    class="color-gray py-15 border-bottom font-14">
                    <el-col :span="4">{{
          $t("contact_about.table2.type")
        }}</el-col>
                    <el-col :span="6">{{
          $t("contact_about.table2.price")
        }}</el-col>
                    <el-col :span="5">{{
          $t("contact_about.table2.amount")
        }}</el-col>
                    <el-col :span="4">{{
          $t("contact_about.table2.time")
        }}</el-col>
                    <el-col class="text-right" :span="4">{{
          $t("contact_about.table2.deal")
        }}</el-col>
                  </el-row>
                  <el-row v-for="(item, index) in contactList" :key="index" :gutter="20" type="flex" justify="center"
                    align="middle" class="py-15 border-bottom font-14">
                    <el-col :span="4">
                      <el-tag v-if="item.type" type="info" effect="plain" hit class="bg-t">Sell</el-tag>
                      <el-tag v-else type="primary" effect="plain" hit class="bg-t">Buy</el-tag>
                    </el-col>
                    <el-col :span="6">{{ item.entrust_price }}</el-col>
                    <el-col :span="5">{{ item.amount }}</el-col>
                    <el-col :span="4">{{ item.create_time }}</el-col>
                    <el-col class="text-right" :span="4">
                      <el-button type="danger" size="small" plain class="bg-t" style="padding: 9px 8px;box-sizing: border-box;"
                        @click="onCancelContactOrder(item.id)">Cancel</el-button>
                    </el-col>
                  </el-row>
                </div>

                <div v-if="contactList.length < 1" class="py-50 font-14 color-i text-center">
                  No More
                </div>

                <div class="pagination-box" v-else>
                  <el-pagination layout="prev, pager, next" background :total="total2" :page-size="pageSize2"
                    :current-page="pageNum2" @current-change="pageFn2">
                  </el-pagination>
                </div>
              </el-tab-pane>
            </el-tabs>

            <!-- <div class="d-flex-center p-16 border-bottom">
              <div class="font-14 font-bold mr-auto color-666">Open Order</div>
              <el-button
                type="primary"
                size="small"
                plain
                class="bg-t"
                @click="fatchTradingList()"
                icon="el-icon-refresh"
                >Refresh</el-button
              >
            </div> -->
          </div>
        </el-col>
        <el-col :span="6">
          <div class="bg-white box-shadow border-r">
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="border-bottom px-20 py-15 font-12">
              <el-col :span="6">{{ $t("middle_window.Position") }}</el-col>
              <el-col :span="9">{{ $t("middle_window.Price") }}</el-col>
              <el-col class="text-right" :span="9">{{
          $t("middle_window.Amount")
        }}</el-col>
            </el-row>
            <div v-if="mbp.asks.length == 5" class="mbp-sell color-s">
              <div v-for="index in [4, 3, 2, 1, 0]" :key="index" @click="onSetPrice(mbp.asks[index][0])"
                class="is-link">
                <el-row :gutter="20" type="flex" justify="center" align="middle"
                  class="border-bottom font-12 px-20 py-10">
                  <el-col :span="6" class="font-14">Sell{{ index + 1 }}</el-col>
                  <el-col :span="9" class="font-14">{{
          mbp.asks[index][0]
        }}</el-col>
                  <el-col :span="9" class="text-right">{{
          Number(mbp.asks[index][1]).toFixed(8) * 1
        }}</el-col>
                </el-row>
              </div>
            </div>
            <div v-if="mbp.bids.length == 5" class="mbp-buy color-d">
              <div v-for="index in [0, 1, 2, 3, 4]" :key="index" @click="onSetPrice(mbp.bids[index][0])"
                class="is-link">
                <el-row :gutter="20" type="flex" justify="center" align="middle"
                  class="border-bottom font-12 px-20 py-10">
                  <el-col :span="6" class="font-14">Buy{{ index + 1 }}</el-col>
                  <el-col :span="9" class="font-14">{{
          mbp.bids[index][0]
        }}</el-col>
                  <el-col :span="9" class="text-right">{{
          Number(mbp.bids[index][1]).toFixed(8) * 1
        }}</el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import coinLogo from "@/assets/coin/logo";
import HQChart from "hqchart";
HQChart.Chart.JSConsole.Chart.Log = () => { };
HQChart.Chart.JSConsole.Complier.Log = () => { };
import request from "@/services/axios";
import swi from "@/assets/icon/huzhuangpage.1c7712ec.svg";
//黑色风格
function GetDevicePixelRatio() {
  return window.devicePixelRatio || 1;
}
const BLACK_STYLE = {
  BGColor: "rgb(23,26,37)", //背景色
  TooltipBGColor: "rgb(255, 255, 255)", //背景色
  TooltipAlpha: 0.92, //透明度

  SelectRectBGColor: "rgba(1,130,212,0.06)", //背景色
  //  SelectRectAlpha: 0.06;                  //透明度

  //K线颜色
  UpBarColor: "rgb(25,158,0)", //上涨
  DownBarColor: "rgb(238,21,21)", //下跌
  UnchagneBarColor: "rgb(228,228,228)", //平盘

  Minute: {
    VolBarColor: "rgb(255,236,0)", //分时图成交量柱子颜色（已经不用了 使用红绿柱)
    PriceColor: "rgb(25,180,231)", //分时图价格线颜色
    AreaPriceColor: "rgba(63,158,255,.3)", //价格的面积图
    AvPriceColor: "rgb(255,236,0)", //分时图均价线颜色
    PositionColor: "rgb(218,165,32)", //持仓量线段颜色
  },

  DefaultTextColor: "rgb(255,255,255)",
  DefaultTextFont: 14 * GetDevicePixelRatio() + "px 微软雅黑",
  TitleFont: 13 * GetDevicePixelRatio() + "px 微软雅黑", //标题字体(动态标题 K线及指标的动态信息字体)

  //K线信息文字
  DownTextColor: "rgb(238,21,21)", //下跌
  UpTextColor: "rgb(25,158,0)", //上涨
  UnchagneTextColor: "rgb(101,104,112)", //平盘
  CloseLineColor: "rgb(178,34,34)", //收盘价线

  FrameBorderPen: "rgba(236,236,236,0.13)", //边框
  FrameSplitPen: "rgba(236,236,236,0.13)", //分割线
  FrameSplitTextColor: "rgb(101,104,112)", //刻度文字颜色
  FrameSplitTextFont: 12 * GetDevicePixelRatio() + "px 微软雅黑", //坐标刻度文字字体
  FrameTitleBGColor: "rgb(31,38,54)", //标题栏背景色

  Frame: { XBottomOffset: 1 * GetDevicePixelRatio() }, //X轴文字向下偏移

  CorssCursorBGColor: "rgb(43,54,69)", //十字光标背景
  CorssCursorTextColor: "rgb(255,255,255)",
  CorssCursorTextFont: 12 * GetDevicePixelRatio() + "px 微软雅黑",
  CorssCursorPenColor: "rgb(130,130,130)", //十字光标线段颜色

  KLine: {
    MaxMin: {
      Font: 12 * GetDevicePixelRatio() + "px 微软雅黑",
      Color: "rgb(111,111,111)",
    }, //K线最大最小值显示
    //信息地雷
    Info: {
      Color: "rgb(205,149,12)",
      TextColor: "#afc0da",
      TextBGColor: "#1a283e",
    },
  },

  Index: {
    //指标线段颜色
    LineColor: [
      "rgb(255,189,09)",
      "rgb(22,198,255)",
      "rgb(174,35,161)",
      "rgb(236,105,65)",
      "rgb(68,114,196)",
      "rgb(229,0,79)",
      "rgb(0,128,255)",
      "rgb(252,96,154)",
      "rgb(42,230,215)",
      "rgb(24,71,178)",
    ],
    NotSupport: { Font: "14px 微软雅黑", TextColor: "rgb(52,52,52)" },
  },

  //自定义指标默认颜色
  ColorArray: [
    "rgb(255,174,0)",
    "rgb(25,199,255)",
    "rgb(175,95,162)",
    "rgb(236,105,65)",
    "rgb(68,114,196)",
    "rgb(229,0,79)",
    "rgb(0,128,255)",
    "rgb(252,96,154)",
    "rgb(42,230,215)",
    "rgb(24,71,178)",
  ],

  //画图工具
  DrawPicture: {
    LineColor: "rgb(30,144,255)",
    PointColor: "rgb(105,105,105)",
  },
};
const LIGHT_STYLE = {
  //K线颜色
  UpBarColor: "rgb(25,158,0)", //上涨
  DownBarColor: "rgb(238,21,21)", //下跌
  UnchagneBarColor: "rgb(228,228,228)", //平盘

  //K线信息文字
  DownTextColor: "rgb(238,21,21)", //下跌
  UpTextColor: "rgb(25,158,0)", //上涨
  UnchagneTextColor: "rgb(101,104,112)", //平盘
  CloseLineColor: "rgb(178,34,34)", //收盘价线
};

const STYLE_TYPE_ID = {
  LIGTH_ID: 0, //黑色风格
  BLACK_ID: 1, //黑色风格
};

function HQChartStyle() { }

HQChartStyle.GetStyleConfig = function (
  styleid //获取一个风格的配置变量
) {
  switch (styleid) {
    case STYLE_TYPE_ID.LIGHT_ID:
      return LIGHT_STYLE;
    case STYLE_TYPE_ID.BLACK_ID:
      return BLACK_STYLE;
    default:
      return null;
  }
};
let Period = 0;

// 目前支持以下事件监听
var JSCHART_EVENT_ID = {
  RECV_KLINE_MATCH: 1, //接收到形态匹配
  RECV_INDEX_DATA: 2, //接收指标数据
  RECV_HISTROY_DATA: 3, //接收到历史数据
  RECV_TRAIN_MOVE_STEP: 4, //接收K线训练,移动一次K线
  CHART_STATUS: 5, //每次Draw() 以后会调用
  BARRAGE_PLAY_END: 6, //单个弹幕播放完成
  RECV_OVERLAY_INDEX_DATA: 7, //接收叠加指标数据
  DBCLICK_KLINE: 8, //双击K线图
  RECV_START_AUTOUPDATE: 9, //开始自动更新
  RECV_STOP_AUTOUPDATE: 10, //停止自动更新
  ON_CONTEXT_MENU: 11, //右键菜单事件
  ON_TITLE_DRAW: 12, //标题信息绘制事件
  ON_SELECT_RECT: 13, //区间选择事件通知
  RECV_MINUTE_DATA: 14, //分时图数据到达
  ON_CLICK_INDEXTITLE: 15, //点击指标标题事件
  RECV_KLINE_UPDATE_DATA: 16, //K线日,分钟更新数据到达
  ON_CLICK_DRAWPICTURE: 17, //点击画图工具
  ON_FINISH_DRAWPICTURE: 18, //完成画图工具
  ON_INDEXTITLE_DRAW: 19, //指标标题重绘事件
  ON_CUSTOM_VERTICAL_DRAW: 20, //自定义X轴绘制事件
  RECV_KLINE_MANUAL_UPDATE_DATA: 21, //手动更新K线事件
  ON_ENABLE_SPLASH_DRAW: 22, //开启/关闭过场动画事件
};

export default {
  data() {
    return {
      chart: null,
      drawType: false,
      coinLogo,
      noticeList: [],
      info: {
        // price: "0.0000",
        // usd: "0.00",
        // change: 0,
        // accuracy: 2,
        // high: "0.00",
        // low: "0.00",
        // volume: "0.00",
      },
      Period: 0,
      KLine: {
        JSChart: null,
        Option: {
          Type: "历史K线图",
          // Language: this.$store.state.locale == "zh-TW" ? "TC" : "EN",
          Language: "EN",
          SplashTitle:
            this.$store.state.locale == "zh-TW" ? "數據加載中" : "Loading",
          //   NetworkFilter: NetworkFilter,
          IsShowRightMenu: false,
          IsAutoUpdate: false,
          IsApiPeriod: true,
          IsShowCorssCursorInfo: true,
          CorssCursorInfo: { Left: 2, Right: 2, IsShowCorss: true },
          // CorssCursorInfo:{
          //   DateFormatType:1
          // },

          //窗口指标
          Windows: [
            {
              Index: "MA",
              Modify: true,
              Change: true,
            },
            {
              Index: "VOL",
              Modify: true,
              Change: true,
            },
          ],
          Border: {
            Left: 0,
            Right: 0, //右边间距
            Top: 25,
            Bottom: 25,
          },

          // 2023.2.27
          //子框架设置
          Frame: [
            {
              SplitCount: 3,
              SplitType: 1,
              // YCoordinateType:2,
              // SplitCount:8,
              // BorderLine:false,
              // IsShowLeftText: false,
              // IsShowXLine:false,IsShowYLine:false,
              // FrameSplitPen: "red",          //分割线
              // FrameSplitTextColor: "red",     //刻度文字颜色
              // FrameTitleBGColor: "red",      //标题栏背景色
              Height: 8,
              TopSpace: 0,
              BottomSpace: 0,
              IsShowLeftText: false, //不显示左边刻度文字
              IsShowRightText: true, //显示右边刻度文字
              Custom: [
                {
                  Type: 0,
                  Position: "right",
                  // IsShowLine: false,
                  LineType: 1,
                  Data: {
                    TextColor: "black",
                  },
                },
              ],
              Data: {
                TextColor: "black",
              },
            },
            {
              SplitCount: 3,
              SplitType: 1,
              IsShowIndexName: false,
              IsShowLeftText: false,
              IsShowRightText: false,
              Height: 2,
              TopSpace: 0,
              BottomSpace: 0,
              // IsShowXLine: false,
              // IsShowYLine: false
            },
          ],

          KLine: {
            Right: 1, //复权 0 不复权 1 前复权 2 后复权
            Period: 0, //周期: 0 日线 1 周线 2 月线 3 年线
            PageSize: 70,
            IsShowTooltip: false,
          },
          KLineTitle: {
            IsShowName: false,
            IsShowSettingInfo: false,
          },
        },
      },
      mbp: {
        bids: [
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
        asks: [
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
      },
      klineLoading: true,
      loading: false,
      dataVersion: 0,
      timer: null,
      // klineSrc: null,
      marketCoin: this.$store.state.marketCoin,
      market: [],
      marketLoading: false,
      marketTimer: null,
      marketDataVersion: 0,
      //
      userInfo: {
        pair: "",
        coin: "",
        usdt: "",
      },
      buyLoading: false,
      buyFormData: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: null,
        amount: null,
        pay_password: null,
      },
      buyFormData1: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: null,
        amount: null,
        pay_password: null,
      },
      sellLoading: false,
      sellFormData: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: null,
        amount: null,
        pay_password: null,
      },
      sellFormData1: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: null,
        amount: null,
        pay_password: null,
      },
      tradingLoading: false,
      tradingList: [],
      contactList: [],
      rules: {
        price: [
          { required: true, trigger: "blur", message: this.$t("rfields") },
        ],
        amount: [
          { required: true, trigger: "blur", message: this.$t("rfields") },
        ],
        pay_password: [{ required: true, trigger: "blur" }],
      },
      orderLoading: false,
      orderShow: false,
      rate: 0,
      lastDueTime: 30,
      dueTimer: null,
      dueTimeList: {
        30: 75,
        60: 80,
        90: 85,
        180: 90,
        300: 95,
      },
      orderFormData: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        due_time: 30,
        type: 0,
        amount: "100",
        total: "78",
        id: 1,
        state: 1,
      },

      coinList: ["usdt", "btc", "eth"],

      //新增
      marketLength: 0,
      socket1: null,
      activeName: "first",
      activeName1: "second",
      // lever1: 5,
      lever1: 10, //2023.2.5
      options1: [
        // {
        //   value: 5,
        //   label: "5x",
        // },
        {
          value: 10,
          label: "10x",
        },
        {
          value: 20,
          label: "20x",
        },
        {
          value: 30,
          label: "30x",
        },
        {
          value: 50,
          label: "50x",
        },
        {
          value: 75,
          label: "75x",
        },
        {
          value: 100,
          label: "100x",
        },
      ],
      userContract: {
        net_worth: "", //净值
        balance: "", //可用余额
        income: "", //持仓收益
        cash_deposit_total: "", //占用保证金
      },
      total1: 0,
      total2: 0,
      pageSize1: 5,
      pageSize2: 5,
      pageNum1: 1,
      pageNum2: 1,
      switch1: true,
      switch2: true,
      labelState: 0,

      new_price_input: "", //点击获取最新价格
      contactList_top: "", //处理顶部信息，不分币种不分类型（全部）

      //互转弹窗
      dialogVisible: false,
      swi,

      recharge_text1: this.$t("contact_about.recharge_text1"),
      recharge_text2: this.$t("contact_about.recharge_text2"),
      recharge_change: false,
      amount_recharge: "",
      amount_balance: "",
      amount_balance1: "", //现货
      amount_balance2: "", //合约
      btn_load: false,

      slider_value: 0,
      slider_value1: 0,
      all_buy: "",
      all_sell: "",

      //市價
      buyLoading_shijia: false,
      sellLoading_shijia: false,
      buyFormData_shijia: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: "",
        amount: "",
        pay_password: "",
      },
      // buyFormData1_shijia: {
      //   pair: this.$route.params.pair,
      //   coin: this.$route.params.coin,
      //   price: null,
      //   amount: null,
      //   pay_password: null,
      // },
      sellFormData_shijia: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        price: "",
        amount: "",
        pay_password: "",
      },
      //市價 -end

      // 2023.2.16新增
      // 买入
      slider_value1_shijia: 0, //市价拖条
      margin_last1: "", //市价剩余保证金
      // 卖出
      slider_value2_shijia: 0, //市价拖条
      margin_last2: "", //市价剩余保证金

      Cache: [],

      digit_unit: 0,

      dataVerifyFlag: false,
    };
  },
  computed: {
    buyTotalValue1: function () {
      if (!this.buyFormData1.price || !this.buyFormData1.amount) return "";
      // return (this.buyFormData1.price * this.buyFormData1.amount).toFixed(8) * 1;
      //保证金 = (价格 / 杠杆) * 数量
      let $num = this.floatingpoint.mul(
        this.floatingpoint.bed(this.buyFormData1.price, this.lever1),
        this.buyFormData1.amount
      );
      $num = this.floatingpoint.toolNumber($num);
      return $num;
    },
    sellTotalValue1: function () {
      // if(!this.sellFormData.amount) return '--'
      // return this.sellFormData.amount
      if (!this.sellFormData1.price || !this.sellFormData1.amount) return "";
      // return (
      //   (this.sellFormData1.price * this.sellFormData1.amount).toFixed(8) * 1
      // );
      //保证金 = (价格 / 杠杆) * 数量
      let $num = this.floatingpoint.mul(
        this.floatingpoint.bed(this.sellFormData1.price, this.lever1),
        this.sellFormData1.amount
      );
      $num = this.floatingpoint.toolNumber($num);
      return $num;
    },
    expectedProfits: function () {
      if (!this.orderFormData.amount) return "0";
      return (
        // this.orderFormData.amount *
        // (this.dueTimeList[this.orderFormData.due_time] / 100 + 1)
        this.floatingpoint.mul(
          this.orderFormData.amount,
          this.dueTimeList[this.orderFormData.due_time] / 100 + 1
        )
      );
    },
    currentRate() {
      if (this.lastDueTime == 0 || this.orderFormData.due_time == 0) return 0;
      if (this.lastDueTime >= this.orderFormData.due_time) return 100;
      const num =
        (this.lastDueTime / this.orderFormData.due_time).toFixed(2).valueOf() *
        100;
      return num;
    },
  },
  watch: {
    marketCoin: function () {
      this.market = [];
      if (["BTC", "ETH", "USDT"].indexOf(this.marketCoin) < 0) return;
      this.$store.commit("marketCoin", this.marketCoin);
      this.tabChange(true);
    },
    $route: function () {
      // this.fatchData();

      this.tabChange(true);

      this.klineLoading = true;
      this.klineSrc =
        "kline.html?symbol=" +
        this.$route.params.pair +
        this.$route.params.coin +
        "&times=86400000";
      //   this.fatchUserInfo();

      this.total1 = 0;
      this.total2 = 0;
      this.pageNum1 = 1;
      this.pageNum2 = 1;
      this.switch1 = true;
      this.switch2 = true;
      this.labelState = 0;

      //   this.fatchTradingList();
      this.fatchContactList();
      this.fatchContactList1();
      this.buyFormData.pair = this.$route.params.pair;
      this.buyFormData.coin = this.$route.params.coin;
      this.buyFormData.price = null;
      this.sellFormData.pair = this.$route.params.pair;
      this.sellFormData.coin = this.$route.params.coin;
      this.sellFormData.price = null;
      this.orderFormData.pair = this.$route.params.pair;
      this.orderFormData.coin = this.$route.params.coin;
      this.orderFormData.amount = "10";

      this.buyFormData1 = {};
      this.sellFormData1 = {};
      this.buyFormData1.pair = this.$route.params.pair;
      this.buyFormData1.coin = this.$route.params.coin;
      this.buyFormData1.price = "";
      this.sellFormData1.pair = this.$route.params.pair;
      this.sellFormData1.coin = this.$route.params.coin;
      this.sellFormData1.price = "";
      this.all_buy = null;
      this.all_sell = null;
      this.new_price_input = null;

      // 2023.2.13 新增市价
      this.buyFormData_shijia = {};
      this.sellFormData_shijia = {};
      this.buyFormData_shijia.pair = this.$route.params.pair;
      this.buyFormData_shijia.coin = this.$route.params.coin;
      this.buyFormData_shijia.price = "";
      this.buyFormData_shijia.amount = "";
      this.buyFormData_shijia.pay_password = "";
      this.sellFormData_shijia.pair = this.$route.params.pair;
      this.sellFormData_shijia.coin = this.$route.params.coin;
      this.sellFormData_shijia.price = "";
      this.sellFormData_shijia.amount = "";
      this.sellFormData_shijia.pay_password = "";
      // 2023.2.13 -end

      // 2023.2.16新增
      // 买入
      this.slider_value1_shijia = 0; //市价拖条
      this.margin_last1 = ""; //市价剩余保证金
      // 卖出
      this.slider_value2_shijia = 0; //市价拖条
      this.margin_last2 = ""; //市价剩余保证金

      this.CreateKLineChart();

      this.fatchUserContract();
      this.spotWallet();
    },
  },
  created: function () {
    this.fatchNotice();

    // this.klineSrc = 'kline.html?symbol='+this.$route.params.pair+this.$route.params.coin
    // this.fatchData();
    // this.timer = window.setInterval(() => {
    //   if (document.hidden) return;
    //   this.fatchData();
    // }, 900);
    this.tabChange(true);
    // this.marketTimer = window.setInterval(() => {
    //   if (document.hidden) return;
    //   if (["BTC", "ETH", "USDT"].indexOf(this.marketCoin) < 0) return;
    //   this.tabChange(false);
    // }, 1900);
    // this.fatchUserInfo();
    // this.fatchTradingList();
    this.fatchContactList();
    this.fatchContactList1();
    this.fatchUserContract();
    this.spotWallet();
  },
  beforeDestroy: function () {
    window.clearInterval(this.timer);
    window.clearInterval(this.marketTimer);
    window.clearInterval(this.dueTimer);
  },
  mounted: function () {
    Period = 0;
    this.Period = 0;

    this.CreateKLineChart();
  },
  destroyed: function () {
    this.socket1.close(); //离开路由之后断开websocket连接
  },
  methods: {
    //输入框焦点获取价格
    formInputFocus: function () {
      if (!this.buyFormData1.price) {
        this.buyFormData1.price = this.new_price_input;
      }
      if (!this.sellFormData1.price) {
        this.sellFormData1.price = this.new_price_input;
      }
    },

    //切换合约的订单类型
    labelStateFn: function (e) {
      this.labelState = e;
      this.total2 = 0;
      this.pageNum2 = 1;
      this.fatchContactList();
    },

    // 原本静态的k线数据
    NetworkFilter(data, callback) {
      let that = this;
      //网络协议回调
      // console.log('[NetworkFilter] data', data);
      data.PreventDefault = true; //设置hqchart不請求数据
      // console.log(data);
      if (
        data.Name != "KLineChartContainer::RequestHistoryData" &&
        data.Name != "KLineChartContainer::ReqeustHistoryMinuteData"
      ) {
        return;
      }

      let timeJson = {
        time_4: 60000,
        time_5: 300000,
        time_6: 900000,
        time_7: 1800000,
        time_0: 216000000,
      };
      // this.StopWSUpdate();//停止ws更新数据
      request
        .post("v1/symbol/getSymbolKine", {
          symbol: this.$route.params.pair + this.$route.params.coin,
          times: timeJson["time_" + this.Period],
        })
        .then((res) => {
          //动态设置默认小数位数
          HQChart.Chart.MARKET_SUFFIX_NAME.GetDefaultDecimal = (symbol) => {
            that.digit_unit = res.data.digit;
            return res.data.digit;
          };
          callback(res.data);
          this.StartWSUpdate(); //加载ws拼接
        })
        .catch((err) => {
          console.error(err);
        });
    },

    //加载ws拼接
    StartWSUpdate() {
      this.socket1.on("symbol_kline", (res) => {
        let $json = JSON.parse(res);
        // console.log($json["5m"])
        if (this.Period == 4) {
          // console.log($json["1m"])
          this.OnRecvWSRealtimeData($json["1m"]);
        }
        if (this.Period == 5) {
          this.OnRecvWSRealtimeData($json["5m"]);
        }
        if (this.Period == 6) {
          this.OnRecvWSRealtimeData($json["15m"]);
        }
        if (this.Period == 7) {
          this.OnRecvWSRealtimeData($json["30m"]);
        }
        if (this.Period == 0) {
          this.OnRecvWSRealtimeData($json["1d"]);
        }
      });
    },

    StopWSUpdate() { }, //停止ws更新数据

    // 数据拼接示例
    OnRecvWSRealtimeData(data) {
      // console.log("[KLineChart::OnRecvWSRealtimeData", data);

      if (data) {
        var hqChartData = { code: 0, data: [], ver: 2.0 };
        hqChartData.digit = this.digit_unit;
        hqChartData.symbol = this.$route.params.pair + this.$route.params.coin;
        hqChartData.name = this.$route.params.pair + this.$route.params.coin;
        // data.forEach((item, index) => {
        //   item = Number(item);
        // });
        for (var i in data) {
          data[i] = Number(data[i]);
          // let val1 = data[6]
          // let val2 = data[7]
          // data[6] = val2
          // data[7] = val1
        }
        hqChartData.data.push(data);
        this.chart.JSChartContainer.RecvMinuteRealtimeData(hqChartData);
      }
    },

    CreateKLineChart: function () {
      let that = this;
      if (this.KLine.JSChart) {
        this.KLine.JSChart.ChangeSymbol(
          this.$route.params.pair + this.$route.params.coin
        );
        return;
      }
      this.KLine.Option.Symbol =
        this.$route.params.pair + this.$route.params.coin;
      this.KLine.Option.Period = Period;
      this.KLine.Option.KLine.Period = Period; //2023.2.15

      // const black = HQChart.Chart.HQChartStyle.GetStyleConfig(HQChart.Chart.STYLE_TYPE_ID.BLACK_ID)
      const style = HQChartStyle.GetStyleConfig(STYLE_TYPE_ID.LIGHT_ID);
      // HQChart.Chart.JSChart.SetStyle(black)
      HQChart.Chart.JSChart.SetStyle(style);
      this.chart = HQChart.Chart.JSChart.Init(this.$refs.kline);
      this.KLine.Option.NetworkFilter = this.NetworkFilter;
      this.chart.SetOption(this.KLine.Option);
      this.KLine.JSChart = this.chart;

      //注册监听事件
      // this.chart.AddEventCallback({
      //   event: JSCHART_EVENT_ID.RECV_START_AUTOUPDATE,
      //   callback: that.startAutoUpdate,
      // });
      // this.chart.AddEventCallback({event:JSCHART_EVENT_ID.RECV_STOP_AUTOUPDATE, callback:stopAutoUpdate});
    },

    ChangePeriod: function (period, drawType) {
      Period = period;
      let that = this;
      this.Period = period;
      this.drawType = drawType;
      if (this.KLine.JSChart) {
        // if(Period==4&&drawType){
        //   // this.drawType = drawType
        //   this.KLine.Option.KLine.DrawType = 4
        // }else{
        //   this.KLine.Option.KLine.DrawType = 0
        // }
        if (Period == 4 && drawType) {
          // this.drawType = drawType;
          this.KLine.Option.KLine.DrawType = 4;
          //显示为k线面积图的时候去掉指标
          // this.KLine.Option.Windows[0].Change = false
          // this.KLine.Option.Windows[0].Modify = false
          // this.KLine.Option.Windows[0].TitleHeight = 0

          this.KLine.Option.Windows[0].Index = "EMPTY";
        } else {
          this.KLine.Option.Windows[0].Index = "MA";
          this.KLine.Option.KLine.DrawType = 0;
        }
        this.KLine.Option.KLine.Period = Period; //2023.2.15
        this.chart.SetOption(this.KLine.Option);
        this.KLine.JSChart = this.chart;
        // this.KLine.JSChart.ChangePeriod(period);

        // this.chart.AddEventCallback({
        //   event: JSCHART_EVENT_ID.RECV_STOP_AUTOUPDATE,
        //   callback: that.stopAutoUpdate,
        // });
        // //注册监听事件
        // this.chart.AddEventCallback({
        //   event: JSCHART_EVENT_ID.RECV_START_AUTOUPDATE,
        //   callback: that.startAutoUpdate,
        // });

        return;
      }
    },
    // ChangePeriod: function (period) {
    //   Period = period;
    //   this.Period = period;
    //   if (this.KLine.JSChart) {
    //     this.KLine.JSChart.ChangePeriod(period);
    //     return;
    //   }
    // },
    fatchNotice: async function () {
      const { data } = await this.$request.post("v1/notice");
      this.noticeList = data.data;
    },

    fatchData: function () {
      if (this.loading) return;
      this.dataVersion++;
      let version = Number(this.dataVersion);
      this.loading = true;
      // console.log('market_trade/index/pair/'+this.$route.params.pair+'/coin/'+this.$route.params.coin)
      this.$requestNotState
        .get(
          "market_trade/index/pair/" +
          this.$route.params.pair +
          "/coin/" +
          this.$route.params.coin
        )
        .then(({ data }) => {
          if (version == this.dataVersion) {
            this.info = data.market;
            // data.mbp.asks = data.mbp.asks.reverse()
            this.mbp = data.mbp;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onKlineLoad: function () {
      this.klineLoading = false;
    },
    onTab: function (active) {
      this.marketCoin = active;
    },

    tabChange: function (ignoreLoadingStatus = false) {
      let that = this;
      this.marketLoading = true;
      this.$requestNotState
        .post("v1/symbol/getList", { coin: this.marketCoin.toLowerCase() })
        .then(({ data }) => {
          // this.market = data
          let arr = {};
          if (data.list) {
            data.list.forEach(function (item, index) {
              // 新增，先获取默认最新价格
              if (
                item.pair == that.$route.params.pair &&
                item.coin == that.$route.params.coin
              ) {
                that.new_price_input = item.lasest_price;
              }

              arr[item.pair + "" + item.coin] = item;
            });
          }
          this.info =
            arr[this.$route.params.pair + "" + this.$route.params.coin];
          this.market = arr;
          this.getdata_wss();
          this.marketLoading = false;
        })
        .catch(() => {
          this.marketLoading = false;
        });
    },

    //获取动态数据
    getdata_wss: function () {
      if (this.socket1) {
        this.socket1.close(); //离开路由之后断开websocket连接
      }

      //wss實時請求
      // this.socket1 = this.$io("ws://47.243.105.117:9502/symbol", {
      this.socket1 = this.$io(this.$wsn + "/symbol", {
        query: {},
        transports: ["websocket", "polling"],
        timeout: 5000,
        // pingTimeout: 20000,
        // pingInterval: 25000,
      });

      this.socket1.on("connect_error", (reason) => {
        console.log(reason);
      });

      this.socket1.on("connect", (socket) => {
        // ws连接已建立，此时可以进行socket.io的事件监听或者数据发送操作
        console.log("ws 已连接");

        //全部报价
        this.socket1.emit("symbol_price_list");
        //单一报价
        this.socket1.emit(
          "symbol_price",
          this.$route.params.pair + "" + this.$route.params.coin
        );
        //交易数据
        this.socket1.emit(
          "symbol_mbp",
          this.$route.params.pair + "" + this.$route.params.coin
        );

        // 2023.5.12新增的动态k线数据
        this.socket1.emit(
          "symbol_kline",
          this.$route.params.pair + "" + this.$route.params.coin
        );

        this.socket1.on("symbol_price_list", (res) => {
          this.market[JSON.parse(res).pair + "" + JSON.parse(res).coin]
            ? (this.market[JSON.parse(res).pair + "" + JSON.parse(res).coin] =
              JSON.parse(res))
            : "";
          this.market = JSON.parse(JSON.stringify(this.market));

          // 匹配最新的价格
          if (
            JSON.parse(res).pair == this.$route.params.pair &&
            JSON.parse(res).coin == this.$route.params.coin
          ) {
            this.new_price_input = JSON.parse(res).lasest_price;
          }

          //业务逻辑匹配计算持仓订单的收益、收益率，最新报价（动态）
          if (this.contactList.length > 0) {
            //确认数据量少，直接foreach,后期改成{}优化
            let $that = this;
            this.contactList.forEach(function (item, index) {
              if (
                item.pair == JSON.parse(res).pair &&
                item.coin == JSON.parse(res).coin &&
                item.state == 2
              ) {
                item.new_price = JSON.parse(res).lasest_price;
                // $that.new_price_input = JSON.parse(res).lasest_price;
                if (item.type) {
                  // 买跌
                  item.income = $that.floatingpoint
                    .mul(
                      $that.floatingpoint.sub(
                        Number(item.open_price),
                        Number(JSON.parse(res).lasest_price)
                      ),
                      Number(item.amount)
                    )
                    .toFixed(8);
                } else {
                  //买涨
                  item.income = $that.floatingpoint
                    .mul(
                      $that.floatingpoint.sub(
                        Number(JSON.parse(res).lasest_price),
                        Number(item.open_price)
                      ),
                      Number(item.amount)
                    )
                    .toFixed(8);
                }
              }
            });
            //顶部信息
          }

          //业务逻辑匹配计算持仓订单的收益、收益率，最新报价（动态）//顶部信息
          if (this.contactList_top.length > 0) {
            //确认数据量少，直接foreach,后期改成{}优化
            let $that = this;
            this.contactList_top.forEach(function (item, index) {
              if (
                item.pair == JSON.parse(res).pair &&
                item.coin == JSON.parse(res).coin &&
                item.state == 2
              ) {
                item.new_price = JSON.parse(res).lasest_price;
                // $that.new_price_input = JSON.parse(res).lasest_price;
                if (item.type) {
                  // 买跌
                  item.income = $that.floatingpoint
                    .mul(
                      $that.floatingpoint.sub(
                        Number(item.open_price),
                        Number(JSON.parse(res).lasest_price)
                      ),
                      Number(item.amount)
                    )
                    .toFixed(8);
                } else {
                  //买涨
                  item.income = $that.floatingpoint
                    .mul(
                      $that.floatingpoint.sub(
                        Number(JSON.parse(res).lasest_price),
                        Number(item.open_price)
                      ),
                      Number(item.amount)
                    )
                    .toFixed(8);
                }
              }
            });
            this.incomeFn();
          }
        });

        this.socket1.on("symbol_price", (res) => {
          this.info = JSON.parse(res);
          // this.info.change = JSON.parse(res).change;
          // this.info.coin = JSON.parse(res).coin;
          // this.info.high = JSON.parse(res).high;
          // this.info.lasest_price = JSON.parse(res).lasest_price;
          // this.info.low = JSON.parse(res).low;
          // this.info.pair = JSON.parse(res).pair;
          // this.info.usd = JSON.parse(res).usd;
          // this.info.volume_24h = JSON.parse(res).volume_24h;
        });

        this.socket1.on("symbol_mbp", (res) => {
          this.mbp = JSON.parse(res);
          // console.log(this.mbp)
        });
      });

      this.socket1.on("disconnect", (reason) => {
        console.log(reason);
      });

      this.socket1.on("error", (msg) => {
        console.log("ws error", msg);
      });
    },

    //现货卖出
    // submitSellForm: async function () {
    //   // try {
    //   //   await this.$refs.sellForm.validate();
    //   //   this.sellLoading = true;
    //   //   await this.$request.post("transaction/sell", this.sellFormData);
    //   //   this.sellFormData.price = null;
    //   //   this.sellFormData.amount = null;
    //   //   this.sellFormData.pay_password = null;
    //   //   this.sellLoading = false;
    //   //   this.fatchTradingList();
    //   // } catch (error) {
    //   //   this.sellLoading = false;
    //   // }

    //   try {
    //     await this.$refs.sellForm.validate();

    //     if (this.sellFormData.amount > Number(this.userInfo.pair)) {
    //       return this.$message({
    //         showClose: true,
    //         type: "error",
    //         duration: 2000,
    //         message: "买入数量不能超过余额",
    //       });
    //     }

    //     this.sellLoading = true;
    //     this.sellFormData.symbol =
    //       this.sellFormData.pair + "" + this.sellFormData.coin;
    //     let $data = await this.$request.post(
    //       "v1/symbol/sell",
    //       this.sellFormData
    //     );

    //     let that = this;
    //     this.$message({
    //       showClose: true,
    //       type: "success",
    //       duration: 2000,
    //       message: $data.message,
    //       onClose: function () {
    //         that.sellFormData.price = null;
    //         that.sellFormData.amount = null;
    //         that.sellFormData.pay_password = null;
    //         that.sellFormData.symbol = null;
    //         that.$refs.sellForm.resetFields(); // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
    //         that.sellLoading = false;
    //         that.total2=0
    //         that.pageNum2=1
    //         that.switch2=true
    //         that.labelState=0
    //         that.fatchTradingList();
    //       },
    //     });
    //   } catch (error) {
    //     this.sellLoading = false;
    //   }
    // },

    //合约买入
    submitBuyForm1: async function () {
      try {
        // 先做权限判断
        // 用户合约交易要判断实名接口v1/user/verify;是否实名，判断跳转，实名后再判断他的is_contract,没有就弹窗给他申请
        let _this = this;
        let $dataVerify = await this.$request.post("v1/user/verify");

        //实名认证：0=未提交 1=失敗 2=審核中 3=成功
        // if ($dataVerify.data.verified != 3) {
        //   //实名未提交,失敗等跳转实名页面
        //   let text_notice = "";
        //   if ($dataVerify.data.verified == 0) {
        //     // text_notice = "您未提交實名認証，請先提交實名認証";
        //     text_notice = _this.$t("contract_shenqin.text_notice1");
        //   } else if ($dataVerify.data.verified == 1) {
        //     // text_notice = "您的實名認証失敗，請重新提交實名認証";
        //     text_notice = _this.$t("contract_shenqin.text_notice2");
        //   } else if ($dataVerify.data.verified == 2) {
        //     // text_notice = "您的實名認証正在審核中";
        //     text_notice = _this.$t("contract_shenqin.text_notice3");
        //   }

        //   if (
        //     $dataVerify.data.verified == 0 ||
        //     $dataVerify.data.verified == 1
        //   ) {
        //     return this.$confirm(
        //       text_notice,
        //       _this.$t("contract_shenqin.tips"),
        //       {
        //         confirmButtonText: _this.$t(
        //           "contract_shenqin.confirmButtonText"
        //         ),
        //         cancelButtonText: _this.$t("contract_shenqin.cancelButtonText"),
        //         type: "warning",
        //       }
        //     )
        //       .then(() => {
        //         console.log("用戶點擊確定，实名");
        //         _this.$nav.push("/user/settings/verify");
        //       })
        //       .catch(() => {
        //         console.log("用戶點擊取消，实名");
        //       });
        //   } else {
        //     return this.$message({
        //       showClose: true,
        //       type: "Warning",
        //       duration: 2000,
        //       message: text_notice,
        //       onClose: function () {},
        //     });
        //   }
        // } else {
        if (
          $dataVerify.data.is_contract == 0 ||
          $dataVerify.data.is_contract == 3
        ) {
          return this.$confirm(
            _this.$t("contract_shenqin.confirm_text"),
            _this.$t("contract_shenqin.tips"),
            {
              confirmButtonText: "確認",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              console.log("用戶點擊確定，合约交易");
              // 申請合約交易權限
              _this.$request.post("v1/user/contractSubmit").then((res) => {
                console.log(res);
                _this.$message({
                  showClose: true,
                  type: "success",
                  duration: 2000,
                  message: res.message,
                  onClose: function () { },
                });
              });
            })
            .catch(() => {
              console.log("用戶點擊取消，合约交易");
            });
        } else {
          if ($dataVerify.data.is_contract == 1) {
            //提交中,弹窗告知
            let dataVerify_text = "";
            // dataVerify_text = "您的申請正在處理中，請等待處理";
            dataVerify_text = _this.$t("contract_shenqin.dataVerify_text");
            return this.$message({
              showClose: true,
              type: "Warning",
              duration: 2000,
              message: dataVerify_text,
              onClose: function () { },
            });
          } else if ($dataVerify.data.is_contract == 2) {
            //成功，正常交易
            console.log("成功，正常交易");
            // 如果跳轉互轉，放這裡
          }
        }
        // }
        // 先做权限判断 -end

        await this.$refs.buyForm1.validate();
        this.buyLoading = true;
        this.buyFormData1.symbol =
          this.buyFormData1.pair + "" + this.buyFormData1.coin;
        this.buyFormData1.level = this.lever1;

        // 處理後台浮點會導致的報錯
        this.buyFormData1.price = this.buyFormData1.price + "";
        this.buyFormData1.amount = this.buyFormData1.amount + "";

        let $data = await this.$request.post(
          "v1/contract/buy",
          this.buyFormData1
        );

        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.buyFormData1.price = null;
            that.buyFormData1.amount = null;
            that.buyFormData1.pay_password = null;
            that.buyFormData1.symbol = null;
            // that.lever1 = 5;
            that.lever1 = 10;
            that.$refs.buyForm1.resetFields(); // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
            that.buyLoading = false;
            that.fatchContactList();
            that.fatchContactList1();
            that.fatchUserContract(); //余额接口
          },
        });
      } catch (error) {
        this.buyLoading = false;
      }
    },

    //合约卖出
    submitSellForm1: async function () {
      try {
        // 先做权限判断
        // 用户合约交易要判断实名接口v1/user/verify;是否实名，判断跳转，实名后再判断他的is_contract,没有就弹窗给他申请
        let _this = this;
        let $dataVerify = await this.$request.post("v1/user/verify");
        // console.log($dataVerify);
        //实名认证：0=未提交 1=失敗 2=審核中 3=成功
        // if ($dataVerify.data.verified != 3) {
        //   //实名未提交,失敗等跳转实名页面
        //   let text_notice = "";
        //   if ($dataVerify.data.verified == 0) {
        //     // text_notice = "您未提交實名認証，請先提交實名認証";
        //     text_notice = _this.$t("contract_shenqin.text_notice1");
        //   } else if ($dataVerify.data.verified == 1) {
        //     // text_notice = "您的實名認証失敗，請重新提交實名認証";
        //     text_notice = _this.$t("contract_shenqin.text_notice2");
        //   } else if ($dataVerify.data.verified == 2) {
        //     // text_notice = "您的實名認証正在審核中";
        //     text_notice = _this.$t("contract_shenqin.text_notice3");
        //   }

        //   if (
        //     $dataVerify.data.verified == 0 ||
        //     $dataVerify.data.verified == 1
        //   ) {
        //     return this.$confirm(
        //       text_notice,
        //       _this.$t("contract_shenqin.tips"),
        //       {
        //         confirmButtonText: _this.$t(
        //           "contract_shenqin.confirmButtonText"
        //         ),
        //         cancelButtonText: _this.$t("contract_shenqin.cancelButtonText"),
        //         type: "warning",
        //       }
        //     )
        //       .then(() => {
        //         console.log("用戶點擊確定，实名");
        //         _this.$nav.push("/user/settings/verify");
        //       })
        //       .catch(() => {
        //         console.log("用戶點擊取消，实名");
        //       });
        //   } else {
        //     return this.$message({
        //       showClose: true,
        //       type: "Warning",
        //       duration: 2000,
        //       message: text_notice,
        //       onClose: function () {},
        //     });
        //   }
        // } else {
        if (
          $dataVerify.data.is_contract == 0 ||
          $dataVerify.data.is_contract == 3
        ) {
          return this.$confirm(
            _this.$t("contract_shenqin.confirm_text"),
            _this.$t("contract_shenqin.tips"),
            {
              confirmButtonText: _this.$t("contract_shenqin.confirmButtonText"),
              cancelButtonText: _this.$t("contract_shenqin.cancelButtonText"),
              type: "warning",
            }
          )
            .then(() => {
              console.log("用戶點擊確定，合约交易");
              // 申請合約交易權限
              _this.$request.post("v1/user/contractSubmit").then((res) => {
                console.log(res);
                _this.$message({
                  showClose: true,
                  type: "success",
                  duration: 2000,
                  message: res.message,
                  onClose: function () { },
                });
              });
            })
            .catch(() => {
              console.log("用戶點擊取消，合约交易");
            });
        } else {
          if ($dataVerify.data.is_contract == 1) {
            //提交中,弹窗告知
            let dataVerify_text = "";
            // dataVerify_text = "您的申請正在處理中，請等待處理";
            dataVerify_text = _this.$t("contract_shenqin.dataVerify_text");
            return this.$message({
              showClose: true,
              type: "Warning",
              duration: 2000,
              message: dataVerify_text,
              onClose: function () { },
            });
          } else if ($dataVerify.data.is_contract == 2) {
            //成功，正常交易
            console.log("成功，正常交易");
            // 如果跳轉互轉，放這裡
          }
        }
        // }
        // 先做权限判断 -end

        await this.$refs.sellForm1.validate();
        this.sellLoading = true;
        this.sellFormData1.symbol =
          this.sellFormData1.pair + "" + this.sellFormData1.coin;
        this.sellFormData1.level = this.lever1;

        // 處理後台浮點會導致的報錯
        this.sellFormData1.price = this.sellFormData1.price + "";
        this.sellFormData1.amount = this.sellFormData1.amount + "";

        let $data = await this.$request.post(
          "v1/contract/sell",
          this.sellFormData1
        );

        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.sellFormData1.price = null;
            that.sellFormData1.amount = null;
            that.sellFormData1.pay_password = null;
            that.sellFormData1.symbol = null;
            // that.lever1 = 5;
            that.lever1 = 10;
            that.$refs.sellForm1.resetFields(); // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
            that.sellLoading = false;
            that.fatchContactList();
            that.fatchContactList1();
            that.fatchUserContract(); //余额接口
          },
        });
      } catch (error) {
        this.sellLoading = false;
      }
    },

    //合约买入 - 市價
    submitBuyForm_shijia: async function () {
      try {
        // 先做权限判断
        // 用户合约交易要判断实名接口v1/user/verify;是否实名，判断跳转，实名后再判断他的is_contract,没有就弹窗给他申请
        let _this = this;
        let $dataVerify = await this.$request.post("v1/user/verify");
        console.log($dataVerify);
        //实名认证：0=未提交 1=失敗 2=審核中 3=成功
        // if ($dataVerify.data.verified != 3) {
        //   //实名未提交,失敗等跳转实名页面
        //   let text_notice = "";
        //   if ($dataVerify.data.verified == 0) {
        //     // text_notice = "您未提交實名認証，請先提交實名認証";
        //     text_notice = _this.$t("contract_shenqin.text_notice1");
        //   } else if ($dataVerify.data.verified == 1) {
        //     // text_notice = "您的實名認証失敗，請重新提交實名認証";
        //     text_notice = _this.$t("contract_shenqin.text_notice2");
        //   } else if ($dataVerify.data.verified == 2) {
        //     // text_notice = "您的實名認証正在審核中";
        //     text_notice = _this.$t("contract_shenqin.text_notice3");
        //   }

        //   if (
        //     $dataVerify.data.verified == 0 ||
        //     $dataVerify.data.verified == 1
        //   ) {
        //     return this.$confirm(
        //       text_notice,
        //       _this.$t("contract_shenqin.tips"),
        //       {
        //         confirmButtonText: _this.$t(
        //           "contract_shenqin.confirmButtonText"
        //         ),
        //         cancelButtonText: _this.$t("contract_shenqin.cancelButtonText"),
        //         type: "warning",
        //       }
        //     )
        //       .then(() => {
        //         console.log("用戶點擊確定，实名");
        //         _this.$nav.push("/user/settings/verify");
        //       })
        //       .catch(() => {
        //         console.log("用戶點擊取消，实名");
        //       });
        //   } else {
        //     return this.$message({
        //       showClose: true,
        //       type: "Warning",
        //       duration: 2000,
        //       message: text_notice,
        //       onClose: function () {},
        //     });
        //   }
        // } else {
        if (
          $dataVerify.data.is_contract == 0 ||
          $dataVerify.data.is_contract == 3
        ) {
          return this.$confirm(
            _this.$t("contract_shenqin.confirm_text"),
            _this.$t("contract_shenqin.tips"),
            {
              confirmButtonText: "確認",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              console.log("用戶點擊確定，合约交易");
              // 申請合約交易權限
              _this.$request.post("v1/user/contractSubmit").then((res) => {
                console.log(res);
                _this.$message({
                  showClose: true,
                  type: "success",
                  duration: 2000,
                  message: res.message,
                  onClose: function () { },
                });
              });
            })
            .catch(() => {
              console.log("用戶點擊取消，合约交易");
            });
        } else {
          if ($dataVerify.data.is_contract == 1) {
            //提交中,弹窗告知
            let dataVerify_text = "";
            // dataVerify_text = "您的申請正在處理中，請等待處理";
            dataVerify_text = _this.$t("contract_shenqin.dataVerify_text");
            return this.$message({
              showClose: true,
              type: "Warning",
              duration: 2000,
              message: dataVerify_text,
              onClose: function () { },
            });
          } else if ($dataVerify.data.is_contract == 2) {
            //成功，正常交易
            console.log("成功，正常交易");
            // 如果跳轉互轉，放這裡
          }
        }
        // }
        // 先做权限判断 -end

        await this.$refs.buyFormShijia.validate();
        this.buyLoading_shijia = true;
        this.buyFormData_shijia.symbol =
          this.buyFormData_shijia.pair + "" + this.buyFormData_shijia.coin;
        this.buyFormData_shijia.level = this.lever1;

        // 處理後台浮點會導致的報錯
        this.buyFormData_shijia.price = this.buyFormData_shijia.price + "";
        this.buyFormData_shijia.amount = this.buyFormData_shijia.amount + "";

        let $data = await this.$request.post(
          "v1/contract/buy",
          this.buyFormData_shijia
        );

        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.buyFormData_shijia.price = "";
            that.buyFormData_shijia.amount = "";
            that.buyFormData_shijia.pay_password = "";
            that.buyFormData_shijia.symbol = "";
            that.margin_last2 = "";
            that.slider_value2_shijia = 0;
            // that.lever1 = 5;
            that.lever1 = 10;
            that.$refs.buyFormShijia.resetFields(); // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
            that.buyLoading_shijia = false;
            that.fatchContactList();
            that.fatchContactList1();
            that.fatchUserContract(); //余额接口
          },
        });
      } catch (error) {
        this.buyLoading_shijia = false;
      }
    },

    //合约賣出 - 市價
    submitSellForm_shijia: async function () {
      try {
        // 先做权限判断
        // 用户合约交易要判断实名接口v1/user/verify;是否实名，判断跳转，实名后再判断他的is_contract,没有就弹窗给他申请
        let _this = this;
        let $dataVerify = await this.$request.post("v1/user/verify");
        console.log($dataVerify);
        //实名认证：0=未提交 1=失敗 2=審核中 3=成功
        // if ($dataVerify.data.verified != 3) {
        //   //实名未提交,失敗等跳转实名页面
        //   let text_notice = "";
        //   if ($dataVerify.data.verified == 0) {
        //     // text_notice = "您未提交實名認証，請先提交實名認証";
        //     text_notice = _this.$t("contract_shenqin.text_notice1");
        //   } else if ($dataVerify.data.verified == 1) {
        //     // text_notice = "您的實名認証失敗，請重新提交實名認証";
        //     text_notice = _this.$t("contract_shenqin.text_notice2");
        //   } else if ($dataVerify.data.verified == 2) {
        //     // text_notice = "您的實名認証正在審核中";
        //     text_notice = _this.$t("contract_shenqin.text_notice3");
        //   }

        //   if (
        //     $dataVerify.data.verified == 0 ||
        //     $dataVerify.data.verified == 1
        //   ) {
        //     return this.$confirm(
        //       text_notice,
        //       _this.$t("contract_shenqin.tips"),
        //       {
        //         confirmButtonText: _this.$t(
        //           "contract_shenqin.confirmButtonText"
        //         ),
        //         cancelButtonText: _this.$t("contract_shenqin.cancelButtonText"),
        //         type: "warning",
        //       }
        //     )
        //       .then(() => {
        //         console.log("用戶點擊確定，实名");
        //         _this.$nav.push("/user/settings/verify");
        //       })
        //       .catch(() => {
        //         console.log("用戶點擊取消，实名");
        //       });
        //   } else {
        //     return this.$message({
        //       showClose: true,
        //       type: "Warning",
        //       duration: 2000,
        //       message: text_notice,
        //       onClose: function () {},
        //     });
        //   }
        // } else {
        if (
          $dataVerify.data.is_contract == 0 ||
          $dataVerify.data.is_contract == 3
        ) {
          return this.$confirm(
            _this.$t("contract_shenqin.confirm_text"),
            _this.$t("contract_shenqin.tips"),
            {
              confirmButtonText: _this.$t("contract_shenqin.confirmButtonText"),
              cancelButtonText: _this.$t("contract_shenqin.cancelButtonText"),
              type: "warning",
            }
          )
            .then(() => {
              console.log("用戶點擊確定，合约交易");
              // 申請合約交易權限
              _this.$request.post("v1/user/contractSubmit").then((res) => {
                console.log(res);
                _this.$message({
                  showClose: true,
                  type: "success",
                  duration: 2000,
                  message: res.message,
                  onClose: function () { },
                });
              });
            })
            .catch(() => {
              console.log("用戶點擊取消，合约交易");
            });
        } else {
          if ($dataVerify.data.is_contract == 1) {
            //提交中,弹窗告知
            let dataVerify_text = "";
            // dataVerify_text = "您的申請正在處理中，請等待處理";
            dataVerify_text = _this.$t("contract_shenqin.dataVerify_text");
            return this.$message({
              showClose: true,
              type: "Warning",
              duration: 2000,
              message: dataVerify_text,
              onClose: function () { },
            });
          } else if ($dataVerify.data.is_contract == 2) {
            //成功，正常交易
            console.log("成功，正常交易");
            // 如果跳轉互轉，放這裡
          }
        }
        // }
        // 先做权限判断 -end

        await this.$refs.sellFormShijia.validate();
        this.sellLoading_shijia = true;
        this.sellFormData_shijia.symbol =
          this.sellFormData_shijia.pair + "" + this.sellFormData_shijia.coin;
        this.sellFormData_shijia.level = this.lever1;

        // 處理後台浮點會導致的報錯
        this.sellFormData_shijia.price = this.sellFormData_shijia.price + "";
        this.sellFormData_shijia.amount = this.sellFormData_shijia.amount + "";

        let $data = await this.$request.post(
          "v1/contract/sell",
          this.sellFormData_shijia
        );

        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.sellFormData_shijia.price = "";
            that.sellFormData_shijia.amount = "";
            that.sellFormData_shijia.pay_password = "";
            that.sellFormData_shijia.symbol = "";
            that.margin_last2 = "";
            that.slider_value2_shijia = 0;
            // that.lever1 = 5;
            that.lever1 = 10;
            that.$refs.sellFormShijia.resetFields(); // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
            that.sellLoading_shijia = false;
            that.fatchContactList();
            that.fatchContactList1();
            that.fatchUserContract(); //余额接口
          },
        });
      } catch (error) {
        this.sellLoading_shijia = false;
      }
    },

    //切换现货合约计算器
    handleClick(tab, event) {
      // console.log(tab, event);
    },

    //切换订单列表
    handleClick1(tab, event) {
      // console.log(tab, event);
    },

    //现货订单列表
    // fatchTradingList: function () {
    //   this.tradingList = [];
    //   if (this.$store.state.userToken == "") {
    //     this.tradingLoading = false;
    //     return;
    //   }
    //   this.tradingLoading = true;
    //   // this.$request
    //   //   .post(
    //   //     "transaction/trading_order/pair/" +
    //   //       this.$route.params.pair +
    //   //       "/coin/" +
    //   //       this.$route.params.coin,
    //   //     this.orderQuery
    //   //   )
    //   //   .then(({ data }) => {
    //   //     this.tradingList = data.data;
    //   //     this.tradingLoading = false;
    //   //   });
    //   this.$request
    //     .post("v1/symbol/getOrderList", {
    //       page: this.pageNum1,
    //       pageSize: this.pageSize1,
    //       symbol: this.switch1
    //         ? this.$route.params.pair + "" + this.$route.params.coin
    //         : "",
    //       state: 0,
    //     })
    //     .then(({ data }) => {
    //       this.tradingList = data.list;
    //       this.total1 = data.total;
    //       this.tradingLoading = false;
    //     });
    // },

    //合约订单列表
    fatchContactList: function () {
      this.contactList = [];
      if (this.$store.state.userToken == "") {
        this.tradingLoading = false;
        return;
      }
      this.tradingLoading = true;
      this.$request
        .post("v1/contract/getOrderList", {
          page: this.pageNum2,
          pageSize: this.pageSize2,
          symbol: this.switch2
            ? this.$route.params.pair + "" + this.$route.params.coin
            : "",
          state: this.labelState == 0 ? 2 : 0,
        })
        .then(({ data }) => {
          let $data = data.list;
          if ($data && $data.length > 0) {
            $data.forEach(function (item, index) {
              item.flag = false;
            });
          }
          this.contactList = $data;
          this.total2 = data.total;
          this.tradingLoading = false;
        });
    },

    //合约订单列表处理顶部信息（不分币种不分类型）
    fatchContactList1: function () {
      this.contactList_top = [];
      this.$request
        .post("v1/contract/getOrderList", {
          page: 1,
          pageSize: 999,
          state: 2,
        })
        .then(({ data }) => {
          let $data = data.list;
          if ($data && $data.length > 0) {
            $data.forEach(function (item, index) {
              item.flag = false;
            });
          }
          this.contactList_top = $data;
        });
    },

    //切换现货当前/全部品种
    // changeFn1: function (e) {
    //   this.switch1 = e;
    //   this.pageNum1 = 1;
    //   this.fatchTradingList();
    // },

    //切换合约当前/全部品种
    changeFn2: function (e) {
      this.switch2 = e;
      this.pageNum2 = 1;
      this.fatchContactList();
    },

    //切换现货页码
    // pageFn1: function (e) {
    //   this.pageNum1 = e;
    //   this.fatchTradingList();
    // },

    //切换合约页码
    pageFn2: function (e) {
      this.pageNum2 = e;
      this.fatchContactList();
    },

    //撤销现货订单
    // onCancelTradingOrder: async function (id) {
    //   try {
    //     this.tradingLoading = true;
    //     let $data = await this.$request.post("v1/symbol/revocation", {
    //       id: id,
    //     });
    //     this.tradingLoading = false;
    //     // this.tradingList.splice(index, 1);
    //     let that = this;
    //     this.$message({
    //       showClose: true,
    //       type: "success",
    //       duration: 2000,
    //       message: $data.message,
    //       onClose: function () {
    //         that.total1=0
    //         that.pageNum1=1
    //         that.switch1=true
    //         that.fatchTradingList();
    //       },
    //     });
    //   } catch (error) {
    //     this.tradingLoading = false;
    //   }
    // },

    //撤销合约订单
    onCancelContactOrder: async function (id) {
      try {
        this.tradingLoading = true;
        let $data = await this.$request.post("v1/contract/revocation", {
          id: id,
        });
        this.tradingLoading = false;
        // this.tradingList.splice(index, 1);
        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.total1 = 0;
            that.pageNum1 = 1;
            that.switch1 = true;
            that.fatchContactList();
            that.fatchContactList1();
          },
        });
      } catch (error) {
        this.tradingLoading = false;
      }
    },

    formInputPrice: function (value = null) {
      if (value !== null) this.buyFormData1.price = value;
      if (!this.buyFormData1.price) {
        return;
      } else if (this.buyFormData1.amount) {
        // this.all_buy = this.floatingpoint.bed(
        //   this.floatingpoint.mul(
        //     this.buyFormData1.price,
        //     this.buyFormData1.amount
        //   ),
        //   Number(this.lever1)
        // );
        this.all_buy =
          Math.round(
            this.floatingpoint.bed(
              this.floatingpoint.mul(
                this.buyFormData1.price,
                this.buyFormData1.amount
              ),
              Number(this.lever1)
            ) * 100000000
          ) / 100000000;
      } else if (this.all_buy) {
        // this.buyFormData1.amount = this.floatingpoint.bed(
        //   this.floatingpoint.mul(this.all_buy, Number(this.lever1)),
        //   this.buyFormData1.price
        // );

        this.buyFormData1.amount =
          Math.round(
            this.floatingpoint.bed(
              this.floatingpoint.mul(this.all_buy, Number(this.lever1)),
              this.buyFormData1.price
            ) * 100000000
          ) / 100000000;
      }
    },
    formInputAmount: function (value = null) {
      if (value !== null) this.buyFormData1.amount = value;
      if (!this.buyFormData1.price || !this.buyFormData1.amount) {
        this.all_buy = "";
        return;
      } else {
        // this.all_buy = this.floatingpoint.bed(
        //   this.floatingpoint.mul(
        //     this.buyFormData1.price,
        //     this.buyFormData1.amount
        //   ),
        //   Number(this.lever1)
        // );

        this.all_buy =
          Math.round(
            this.floatingpoint.bed(
              this.floatingpoint.mul(
                this.buyFormData1.price,
                this.buyFormData1.amount
              ),
              Number(this.lever1)
            ) * 100000000
          ) / 100000000;
      }
    },
    formInputTotalCoin: function (value = null) {
      if (value !== null) this.all_buy = value;
      if (!this.buyFormData1.price || !this.all_buy) {
        this.buyFormData1.amount = "";
        return;
      } else {
        // this.buyFormData1.amount = this.floatingpoint.bed(
        //   this.floatingpoint.mul(this.all_buy, Number(this.lever1)),
        //   this.buyFormData1.price
        // );

        this.buyFormData1.amount =
          Math.round(
            this.floatingpoint.bed(
              this.floatingpoint.mul(this.all_buy, Number(this.lever1)),
              this.buyFormData1.price
            ) * 100000000
          ) / 100000000;
      }
    },

    // 2023.2.16新增市价买入拖条
    formInputSlider1_shijia: function (e = null) {
      if (this.userContract.balance <= 0) {
        return;
      }
      this.buyFormData_shijia.amount =
        Math.round(
          this.floatingpoint.mul(
            this.userContract.balance,
            this.floatingpoint.bed(e, 100)
          ) * 100000000
        ) / 100000000;
      this.buyFormData_shijia.amount = this.floatingpoint.toolNumber(
        this.buyFormData_shijia.amount
      );
      this.margin_last1 =
        Math.round(
          this.floatingpoint.sub(
            this.userContract.balance,
            this.buyFormData_shijia.amount
          ) * 100000000
        ) / 100000000;
      this.margin_last1 = this.floatingpoint.toolNumber(this.margin_last1);
      return;
    },
    // 2023.2.16新增市价卖出拖条
    formInputSlider2_shijia: function (e = null) {
      if (this.userContract.balance <= 0) {
        return;
      }
      this.sellFormData_shijia.amount =
        Math.round(
          this.floatingpoint.mul(
            this.userContract.balance,
            this.floatingpoint.bed(e, 100)
          ) * 100000000
        ) / 100000000;
      this.sellFormData_shijia.amount = this.floatingpoint.toolNumber(
        this.sellFormData_shijia.amount
      );
      this.margin_last2 =
        Math.round(
          this.floatingpoint.sub(
            this.userContract.balance,
            this.sellFormData_shijia.amount
          ) * 100000000
        ) / 100000000;
      this.margin_last2 = this.floatingpoint.toolNumber(this.margin_last2);
      return;
    },
    // 2023.2.16新增市价监听保证金输入框 买入
    marginFn1: function (e) {
      if (e == 0) {
        this.margin_last1 = "";
        return;
      }
      this.margin_last1 =
        Math.round(
          this.floatingpoint.sub(this.userContract.balance, e) * 100000000
        ) / 100000000;
      this.margin_last1 = this.floatingpoint.toolNumber(this.margin_last1);
    },
    // 2023.2.16新增市价监听保证金输入框 卖出
    marginFn2: function (e) {
      if (e == 0) {
        this.margin_last2 = "";
        return;
      }
      this.margin_last2 =
        Math.round(
          this.floatingpoint.sub(this.userContract.balance, e) * 100000000
        ) / 100000000;
      this.margin_last2 = this.floatingpoint.toolNumber(this.margin_last2);
    },

    //做多做空一样
    formInputSlider: function (e = null) {
      if (this.userContract.balance <= 0) {
        this.formInputTotalCoin("");
        return;
      }
      // const coin = this.floatingpoint.bed(
      //   this.floatingpoint.mul(this.userContract.balance, e),
      //   100
      // );

      const coin =
        Math.round(
          this.floatingpoint.bed(
            this.floatingpoint.mul(this.userContract.balance, e),
            100
          ) * 100000000
        ) / 100000000;
      this.formInputTotalCoin(coin ? coin : "");
      return;
    },

    formInputPrice1: function (value = null) {
      if (value !== null) this.sellFormData1.price = value;
      if (!this.sellFormData1.price) {
        return;
      } else if (this.sellFormData1.amount) {
        // this.all_sell = this.floatingpoint.bed(
        //   this.floatingpoint.mul(
        //     this.sellFormData1.price,
        //     this.sellFormData1.amount
        //   ),
        //   Number(this.lever1)
        // );

        this.all_sell =
          Math.round(
            this.floatingpoint.bed(
              this.floatingpoint.mul(
                this.sellFormData1.price,
                this.sellFormData1.amount
              ),
              Number(this.lever1)
            ) * 100000000
          ) / 100000000;
        this.all_sell = this.floatingpoint.toolNumber(this.all_sell);
      } else if (this.all_sell) {
        // this.sellFormData1.amount = this.floatingpoint.bed(
        //   this.floatingpoint.mul(this.all_sell, Number(this.lever1)),
        //   this.sellFormData1.price
        // );

        this.sellFormData1.amount =
          Math.round(
            this.floatingpoint.bed(
              this.floatingpoint.mul(this.all_sell, Number(this.lever1)),
              this.sellFormData1.price
            ) * 100000000
          ) / 100000000;
        this.sellFormData1.amount = this.floatingpoint.toolNumber(
          this.sellFormData1.amount
        );
      }
    },

    formInputAmount1: function (value = null) {
      if (value !== null) this.sellFormData1.amount = value;
      if (!this.sellFormData1.price || !this.sellFormData1.amount) {
        this.all_sell = "";
        return;
      } else {
        // this.all_sell = this.floatingpoint.bed(
        //   this.floatingpoint.mul(
        //     this.sellFormData1.price,
        //     this.sellFormData1.amount
        //   ),
        //   Number(this.lever1)
        // );

        this.all_sell =
          Math.round(
            this.floatingpoint.bed(
              this.floatingpoint.mul(
                this.sellFormData1.price,
                this.sellFormData1.amount
              ),
              Number(this.lever1)
            ) * 100000000
          ) / 100000000;
        this.all_sell = this.floatingpoint.toolNumber(this.all_sell);
      }
    },
    formInputTotalCoin1: function (value = null) {
      if (value !== null) this.all_sell = value;
      if (!this.sellFormData1.price || !this.all_sell) {
        this.sellFormData1.amount = "";
        return;
      } else {
        // this.sellFormData1.amount = this.floatingpoint.bed(
        //   this.floatingpoint.mul(this.all_sell, Number(this.lever1)),
        //   this.sellFormData1.price
        // );

        this.sellFormData1.amount =
          Math.round(
            this.floatingpoint.bed(
              this.floatingpoint.mul(this.all_sell, Number(this.lever1)),
              this.sellFormData1.price
            ) * 100000000
          ) / 100000000;
        this.sellFormData1.amount = this.floatingpoint.toolNumber(
          this.sellFormData1.amount
        );
      }
    },

    //做多做空一样
    formInputSlider1: function (e = null) {
      if (this.userContract.balance <= 0) {
        this.formInputTotalCoin1("");
        return;
      }
      // const pair = this.floatingpoint.bed(
      //   this.floatingpoint.mul(this.userContract.balance, e),
      //   100
      // );

      const pair =
        Math.round(
          this.floatingpoint.bed(
            this.floatingpoint.mul(this.userContract.balance, e),
            100
          ) * 100000000
        ) / 100000000;
      this.formInputTotalCoin1(pair ? pair : "");
      return;
    },

    //平仓
    cleanFn: async function (id) {
      try {
        this.tradingLoading = true;
        let $data = await this.$request.post("v1/contract/close", {
          id: id,
        });
        this.tradingLoading = false;
        // this.tradingList.splice(index, 1);
        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.total1 = 0;
            that.pageNum1 = 1;
            that.switch1 = true;
            that.fatchContactList();
            that.fatchContactList1();
          },
        });
      } catch (error) {
        this.tradingLoading = false;
      }
    },

    //选择杠杆（通用） selectFn
    selectFn: function (e) {
      this.lever1 = e;
      // 限价
      this.all_buy = "";
      this.all_sell = "";
      this.slider_value = 0;
      this.slider_value1 = 0;
      this.buyFormData1.price = "";
      this.sellFormData1.price = "";
      this.buyFormData1.amount = "";
      this.sellFormData1.amount = "";
      // 市价
      this.buyFormData_shijia.price = "";
      this.sellFormData_shijia.price = "";
      this.buyFormData_shijia.amount = "";
      this.sellFormData_shijia.amount = "";
    },

    //获取合约钱包余额及顶部信息
    fatchUserContract: async function () {
      this.userContract = {
        net_worth: "", //净值
        balance: "", //可用余额
        income: "", //持仓收益
        cash_deposit_total: "", //占用保证金
      };
      try {
        const { data } = await this.$request.post(
          "v1/contract/getAccountBalance"
        );
        this.userContract = data;
        this.amount_balance2 = data.balance;
        if (this.recharge_change) {
          this.amount_balance = this.amount_balance2;
        }
      } catch (e) {
        //TODO handle the exception
      }
    },

    //获取现货钱包余额
    spotWallet: async function () {
      try {
        const { data } = await this.$request.post(
          "v1/symbol/getAccountBalance",
          { coin: this.$route.params.coin, pair: this.$route.params.pair }
        );
        this.amount_balance1 = data.balance.balance_coin;
        if (!this.recharge_change) {
          this.amount_balance = this.amount_balance1; //默认是现货转合约
        }
      } catch (e) {
        //TODO handle the exception
      }
    },

    //顶部信息
    incomeFn: function (e) {
      let $income = 0;
      let $that = this;
      if (this.contactList_top) {
        this.contactList_top.forEach(function (item, index) {
          $income = $that.floatingpoint.add(
            Number($income),
            Number(item.income)
          );
        });
        $that.userContract.income = $income.toFixed(8);
        $that.userContract.net_worth = $that.floatingpoint
          .add(
            $that.floatingpoint.add(
              $that.userContract.income,
              $that.userContract.balance
            ),
            $that.userContract.cash_deposit_total
          )
          .toFixed(8);
      }
    },

    formatOrderProgress() {
      return `${this.lastDueTime}s`;
    },

    //微合约下单后返回结果接口
    fatchOrderItem: function () {
      if (!this.orderFormData.id) return;
      this.$request
        // .post("contract/item", this.orderFormData)
        .post("v1/time_set/getInfo", this.orderFormData)
        .then(({ data }) => {
          if (data.state == 0) {
            window.setTimeout(() => {
              this.fatchOrderItem;
            }, 500);
          } else {
            this.orderFormData.state = data.state;
          }
        });
    },

    //微合约-下单
    submitOrderForm: async function () {
      try {
        // await this.$refs.orderForm.validate();
        // this.orderLoading = true;
        // const { data } = await this.$request.post(
        //   "contract/submit",
        //   this.orderFormData
        // );
        // this.orderLoading = false;
        // this.lastDueTime = this.orderFormData.due_time;
        // this.orderFormData.id = data.id;
        // this.orderFormData.total = data.total;
        // this.dueTimer = window.setInterval(() => {
        //   if (this.lastDueTime <= 0) {
        //     window.clearInterval(this.dueTimer);
        //     this.fatchOrderItem();
        //     return;
        //   }
        //   this.lastDueTime--;
        // }, 1000);

        await this.$refs.orderForm.validate();
        this.orderLoading = true;
        const { data } = await this.$request.post(
          "v1/time_set/create",
          this.orderFormData
        );
        this.orderLoading = false;
        this.lastDueTime = this.orderFormData.due_time;
        this.orderFormData.id = data.id;
        this.orderFormData.total = data.total;
        this.dueTimer = window.setInterval(() => {
          if (this.lastDueTime <= 0) {
            window.clearInterval(this.dueTimer);
            this.fatchOrderItem();
            return;
          }
          this.lastDueTime--;
        }, 1000);
      } catch (error) {
        this.orderLoading = false;
      }
    },

    onOrderShow: function (type = -1) {
      window.clearInterval(this.dueTimer);
      this.orderShow = true;
      this.orderFormData.id = 0;
      this.orderFormData.state = 0;
      if (type !== -1) {
        this.orderFormData.type = type;
      }
    },
    onOrderHide: function () {
      this.orderShow = false;
      window.clearInterval(this.dueTimer);
    },

    //资金互转
    transferFn: async function () {
      // 先做权限判断
      // 用户合约交易要判断实名接口v1/user/verify;是否实名，判断跳转，实名后再判断他的is_contract,没有就弹窗给他申请
      let _this = this;
      _this.dataVerifyFlag = true;
      let $dataVerify = await this.$request.post("v1/user/verify");
      // console.log($dataVerify);
      //实名认证：0=未提交 1=失敗 2=審核中 3=成功
      //2023.3.15资金互转去掉实名验证
      // if ($dataVerify.data.verified != 3) {
      //   //实名未提交,失敗等跳转实名页面
      //   let text_notice = "";
      //   if ($dataVerify.data.verified == 0) {
      //     // text_notice = "您未提交實名認証，請先提交實名認証";
      //     text_notice = _this.$t("contract_shenqin.text_notice1");
      //   } else if ($dataVerify.data.verified == 1) {
      //     // text_notice = "您的實名認証失敗，請重新提交實名認証";
      //     text_notice = _this.$t("contract_shenqin.text_notice2");
      //   } else if ($dataVerify.data.verified == 2) {
      //     // text_notice = "您的實名認証正在審核中";
      //     text_notice = _this.$t("contract_shenqin.text_notice3");
      //   }

      //   if ($dataVerify.data.verified == 0 || $dataVerify.data.verified == 1) {
      //     return this.$confirm(text_notice, _this.$t("contract_shenqin.tips"), {
      //       confirmButtonText: _this.$t("contract_shenqin.confirmButtonText"),
      //       cancelButtonText: _this.$t("contract_shenqin.cancelButtonText"),
      //       type: "warning",
      //     })
      //       .then(() => {
      //         console.log("用戶點擊確定，实名");
      //         _this.$nav.push("/user/settings/verify");
      //       })
      //       .catch(() => {
      //         console.log("用戶點擊取消，实名");
      //       });
      //   } else {
      //     return this.$message({
      //       showClose: true,
      //       type: "Warning",
      //       duration: 2000,
      //       message: text_notice,
      //       onClose: function () {},
      //     });
      //   }
      // } else {
      if (
        $dataVerify.data.is_contract == 0 ||
        $dataVerify.data.is_contract == 3
      ) {
        return this.$confirm(
          _this.$t("contract_shenqin.confirm_text"),
          // "合約交易需要資產達到10000USDT以上，請提交申請",
          _this.$t("contract_shenqin.tips"),
          // "提示",
          {
            confirmButtonText: _this.$t("contract_shenqin.confirmButtonText"),
            cancelButtonText: _this.$t("contract_shenqin.cancelButtonText"),
            type: "warning",
          }
        )
          .then(() => {
            console.log("用戶點擊確定，合约交易");
            // 申請合約交易權限
            _this.$request.post("v1/user/contractSubmit").then((res) => {
              console.log(res);
              _this.$message({
                showClose: true,
                type: "success",
                duration: 2000,
                message: res.message,
                onClose: function () { },
              });
            });
          })
          .catch(() => {
            console.log("用戶點擊取消，合约交易");
          });
      } else {
        if ($dataVerify.data.is_contract == 1) {
          //提交中,弹窗告知
          let dataVerify_text = "";
          // dataVerify_text = "您的申請正在處理中，請等待處理";
          dataVerify_text = _this.$t("contract_shenqin.dataVerify_text");
          return this.$message({
            showClose: true,
            type: "Warning",
            duration: 2000,
            message: dataVerify_text,
            onClose: function () { },
          });
        } else if ($dataVerify.data.is_contract == 2) {
          //成功，正常交易
          console.log("成功，正常交易");
          _this.dialogVisible = true;
          _this.dataVerifyFlag = false;
          // 如果跳轉互轉，放這裡
        }
      }
      // }//2023.3.15资金互转去掉实名验证-end
      // 先做权限判断 -end

      // 原本直接打開互轉窗口
      // this.dialogVisible = true;
    },

    //切换互转
    rechargeSwitch: function () {
      this.recharge_change = !this.recharge_change;
      var text = this.recharge_text1;
      this.recharge_text1 = this.recharge_text2;
      this.recharge_text2 = text;
      if (this.recharge_change) {
        this.amount_balance = this.amount_balance2;
      } else {
        this.amount_balance = this.amount_balance1;
      }
    },

    closeDialog: function () {
      //关闭的时候确保下次打开是现货转合约，如果当前是合约转现货则关闭的时候执行次切换确保是现货转合约
      if(this.recharge_change){
        this.rechargeSwitch()
      }
    },

    //提交互转
    submitRecharge: function () {
      // this.dialogVisible = false
      this.btn_load = true;
      let that = this;
      if (
        !this.amount_recharge ||
        Number(this.amount_recharge) > Number(this.amount_balance)
      ) {
        return this.$message({
          showClose: true,
          type: "error",
          duration: 2000,
          message: that.$t("contact_about.error1"),
        });
      }
      let $url = ["v1/contract/recharge", "v1/contract/withdraw"];
      let $index = this.recharge_change ? 1 : 0;
      this.$request
        .post($url[$index], { money: this.amount_recharge })
        .then((res) => {
          console.log(res);
          this.$message({
            showClose: true,
            type: "success",
            duration: 2000,
            message: res.message,
            onClose: function () {
              that.btn_load = false;
              that.dialogVisible = false;
              that.amount_recharge = "";
              that.recharge_change = false;
              that.fatchUserContract(); //现货钱包
              that.spotWallet(); //合约钱包
            },
          });
        })
        .catch((e) => {
          this.btn_load = false;
        });
    },
  },
};
</script>

<style>
.white-form input {
  color: black !important;
}
</style>

<style lang="scss" scoped>
.notice-bar {
  .link {
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.top-info {
  .pair-coin {
    font-size: 32px;
  }
}

.kline-box {
  position: relative;

  .kline-tools {
    position: absolute;
    top: 10px;
    right: 20px;
  }

  .kline-view {
    height: 500px;
    border: none;
    width: 1160px;
  }
}

.tabs {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $bgColor;

  .tab {
    padding-bottom: 18px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }

  .active {
    color: $--color-primary;
    border-bottom: 2px solid $--color-primary;
  }
}

.mbp-buy {
  background-color: rgba(255, 59, 59, 0.07);
}

.mbp-sell {
  background-color: rgba(41, 194, 120, 0.07);
}

// 新增样式
.top-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-textcontent {
  margin-right: 0;
}

.right-textcontent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.right-textcontent>div {
  min-width: 128px;
  padding-left: 20px;
  box-sizing: border-box;
}

.right-textcontent>div:nth-child(2) {
  min-width: 118px;
}

.right-textcontent>div:nth-last-child(1) {
  min-width: 148px;
}

.theme-title {
  font-size: 15px;
  margin: 20px 0 0;
  padding: 0 20px;
  box-sizing: border-box;
}

.theme-title span {
  padding-left: 10px;
  border-left: 3px solid #f9a825;
  color: #333;
}

.top-info .left-textcontent h1 {
  font-size: 34px;
}

.radio-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.radio-group label {
  margin: 0 5px 10px 5px !important;
}

.newstyle {
  padding: 20px;
}

.newstyle-col {
  padding-top: 20px !important;
}

// 顶部信息样式
.top-text {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebedf0;
  box-sizing: border-box;
}

.top-text .top-text-item {
  flex: 1;
  text-align: center;
  padding: 0 10px;
  box-sizing: border-box;
}

.top-text-h {
  font-size: 14px;
  color: #999999;
}

.top-text-p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.in-select {
  width: 120px;
  font-size: 12px;
}

.pagination-box {
  text-align: center;
  padding-top: 10px;
  box-sizing: border-box;
}

// 订单类型
.etab-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid #e3e6e9;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.title-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.title-label-item {
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 14px;
}

.title-label-cli {
  width: 1px;
  height: 16px;
  background: #999;
}

.title-label .active {
  color: #f9a825;
}

// 持仓列表
// <div class="coin-zoom">
//     <span class="coin-zoom-icon">
//     <i class="el-icon-caret-bottom"></i>
//     </span>
//     <div class="coin-zoom-into">
//     <strong>BTC / USDT</strong>
//     <span>Buy</span>
//     </div>
// </div>
.coin-zoom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.coin-zoom-into {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.coin-zoom-into strong {
  font-size: 14px;
  line-height: 14px;
}

.coin-zoom-icon {
  width: 14px;
  height: 14px;
  transition: all ease 0.5s;
}

.detail-row {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px;
  box-sizing: border-box;
}

.detail-col {
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // align-items: flex-start;
  padding: 0 10px;
  box-sizing: border-box;
}

.span-h {
  font-size: 12px;
  color: #999;
  line-height: 20px;
  display: block;
  margin-bottom: 5px;
}

.span-p {
  display: block;
  font-size: 14px;
  color: #333;
  line-height: 20px;
  font-weight: bold;
}

.detail-row:nth-child(1) .detail-col {
  width: 25%;
}

.detail-row:nth-child(1) .detail-col:nth-child(4) {
  text-align: right;
}

.detail-row:nth-child(2) .detail-col {
  width: 33.3%;
}

.detail-row:nth-child(2) .detail-col:nth-child(3) {
  text-align: right;
}

.contactlist-item-detail {
  background: #f1f1f1;
  overflow: hidden;
  height: 0px;
  transition: all ease 0.5s;
}

.contactlist-item-detail-into {
  padding: 10px 0;
  box-sizing: border-box;
}

.contactlist-item-active .coin-zoom-icon {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  /* IE 9 */
  -moz-transform: rotate(180deg);
  /* Firefox */
  -webkit-transform: rotate(180deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(180deg);
  /* Opera */
}

.contactlist-item-into {
  cursor: pointer;
}

.contactlist-item-active .contactlist-item-detail {
  height: 150px;
}

// 资金互转结构（搬迁）
// 新钱包互转样式
.trading-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: rgb(247, 247, 247);
  margin-bottom: 16px;
}

.trading-box1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 40px;
}

.trading-box3 {
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px 0px rgb(230, 230, 230);
}

.switch .huzhuan-icon {
  width: 24px;
  height: 24px;
}

.form-point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgb(53, 110, 245);
  margin: 3px 0;
}

.to-point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #e64340;
  margin: 4px 0;
}

.point-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.point-list .point {
  background: rgb(230, 230, 230);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 4px 0;
}

.trading-box2 {
  width: calc(100% - 112px);
}

.trading-box2-into {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.trading-box2-into.from {
  border-bottom: 1px solid #ebedf0;
}

.trading-box2-title {
  font-size: 14px;
  color: #999;
  line-height: 50px;
  width: 50px;
}

.uni-list-cell-db {
  width: calc(100% - 50px);
  height: 50px;
}

.uni-list-cell-db uni-picker {
  height: 50px;
}

// .uni-list-cell-db .uni-input {
//   line-height: 50px;
//   height: 50px;
//   box-sizing: border-box;
//   background: url(../../static/icon/fund_list_arrow_d.png) no-repeat 182px 24px;
//   background-size: 8px;
// }

.text-content-item {
  margin-bottom: 20px;
}

.text-content-item:nth-child(2) {
  margin-bottom: 10px;
}

.text-c-h {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.text-c-p .coin {
  height: 40px;
}

.text-c-p1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.amount-after {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
}

.amount-unit {
  color: #999;
}

.cli {
  height: 14px;
  width: 1px;
  background: #ebedf0;
  margin: 0 10px;
}

.all {
  color: #356ef5;
}

.amount {
  height: 40px;
  width: 240px;
}

.text-c-p {
  border-bottom: 1px solid #ebedf0;
  box-sizing: border-box;
}

.balance {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.balance_h {
  color: #999;
}

.balance_p {
  color: #e64340;
  font-weight: bold;
}

.switch-hover {
  background: #f1f1f1;
}

// 基础样式上修改
.uni-list-cell-db {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.coin.inp {
  width: 100%;
  background: #fff;
  outline: none;
  border: 0;
}

.amount.inp {
  width: auto !important;
  outline: none;
  border: 0;
}

.switch {
  cursor: pointer;
}

.all {
  cursor: pointer;
}

// -end

//新增拖条
.slider_box {
  // padding: 0 10px;
  box-sizing: border-box;
}

.slider_style_class {
  // height: 4px!important;
  transform: scale(0.8);
}

.slider_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider_box_into {
  width: 85%;
}

.slider_box_tooltip {
  width: 15%;
}

// k线水印
.kline-view {
  background: url(../../assets/index/shui.png) no-repeat 10px 266px;
}

// 以當前最優價格交易
.youjia {
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  line-height: 20px;
  padding: 8px 10px;
  color: #999;
  box-sizing: border-box;
  background: #f1f1f1;
  border: 1px solid #dfdfdf;
  text-align: center;
}
</style>